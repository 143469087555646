import async from "../components/Async";
import React, { Component }  from 'react';
import {
  faBook,
  faChartPie,
  faCheckSquare,
  faDesktop,
  faFile,
  faFlask,
  faHeart,
  faHome,
  faMapMarkerAlt,
  faMoneyBill,
  faTable,
  faUser,
  faUserCircle,
  faClock,
  faIdCard,
  faSignInAlt,
  faMicrophone,
  faCalendar,
  faImages,
  faList,
  faVolumeUp,
  faUserTie,
  faUserPlus,
  faUserFriends,
  faWarehouse,
  faLandmark,
  faFolderOpen,
  faUserSlash,
  faFileExcel,
  faMobile,
  faCommentAlt,
  faExclamationTriangle,
   faPrint,faTrophy,faTv,faCartPlus,faCalendarCheck,faCalendarDay,faBusinessTime,faFileAlt,faChalkboardTeacher,faBlog,faCompressArrowsAlt,faTruck
  
} from "@fortawesome/free-solid-svg-icons";

import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import * as Icon from "react-feather";

import * as myConstClass from '../constant.js';
import {faCog,} from "@fortawesome/free-solid-svg-icons";


// Landing
// import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Reset from "../pages/auth/Reset";
import Careers from "../pages/careers/careers1";
import Careersa from "../pages/auth/careers1";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
import SidebarLeft from "../pages/layouts/SidebarLeft";
import SidebarRight from "../pages/layouts/SidebarRight";
import ThemeModern from "../pages/layouts/ThemeModern";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeDark from "../pages/layouts/ThemeDark";
import ThemeLight from "../pages/layouts/ThemeLight";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Notifications from "../pages/ui-elements/Notifications";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";


import Landing from "../pages/careers/careers";
import LandingCareer from "../pages/careers/careerpublic";
import LandingCareerCompany from "../pages/careers/Careernew";
// Pages
const Settings = async(() => import("../pages/pages/Settings"));
const Clients = async(() => import("../pages/pages/Clients"));
const Invoice = async(() => import("../pages/pages/Invoice"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Tasks = async(() => import("../pages/pages/Tasks"));

// Documentation
const Documentation = async(() => import("../pages/docs/Documentation"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicElements = async(() => import("../pages/forms/BasicElements"));
const AdvancedElements = async(() => import("../pages/forms/AdvancedElements"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const AdvancedTables = async(() => import("../pages/tables/Advanced"));
//Measures
const MListing = async(() => import("../pages/measures/Listing"));
const MacroListing = async(() => import("../pages/measures/MacroListing"));
const MicroListing = async(() => import("../pages/measures/MicroListing"));
const Add = async(() => import("../pages/measures/Validation"));
const MacroAdd = async(() => import("../pages/measures/MacroValidation"));
const MicroAdd = async(() => import("../pages/measures/MicroValidation"));

//Ingredients

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const IonIcons = async(() => import("../pages/icons/IonIcons"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));


//Signup Request
const SListing = async(() => import("../pages/signup/Listing"));
const SView = async(() => import("../pages/signup/view"));
const SMsg = async(() => import("../pages/signup/message"));

//HR Admin
const HRListing = async(() => import("../pages/hradmin/Listing"));
const HRAdd = async(() => import("../pages/hradmin/Validation"));

//for employee
const EListing = async(() => import("../pages/employee/Listing"));
const EAdd = async(() => import("../pages/employee/Validation"));
const EProfile = async(() => import("../pages/employee/profileinfo"));

const EListingDocs = async(() => import("../pages/employee/ListingDocs"));
const EListingAssts = async(() => import("../pages/employee/ListingAssts"));
const EListingPoints = async(() => import("../pages/employee/ListingPoints"));
const EListingPromotions = async(() => import("../pages/employee/morepromotions"));
const Acesscode = async(() => import("../pages/employee/Acesscode"));

//for employee bulk import
const EbAdd = async(() => import("../pages/employee_bulk_import/Import"));

//for organization
const OListing = async(() => import("../pages/organization/Listing"));
const OAdd = async(() => import("../pages/organization/Validation"));

//for Attendance
const AListing = async(() => import("../pages/attendance/Listing"));
const AAdd = async(() => import("../pages/attendance/Validation"));
const AView = async(() => import("../pages/attendance/view"));
const BView = async(() => import("../pages/attendance/checkinout"));
//
const AbsenceListing = async(() => import("../pages/absent/Listing"));

//for Leaves
const LListing = async(() => import("../pages/leaves/Listing"));
const LAdd = async(() => import("../pages/leaves/Validation"));

//for Announcements
const AnListing = async(() => import("../pages/announcements/Listing"));
const AnAdd = async(() => import("../pages/announcements/Validation"));

//for adminapp

const AdListing = async(() => import("../pages/adminapp/Listing"));
const AdAdd = async(() => import("../pages/adminapp/Validation"));


const PAListing = async(() => import("../pages/paycode/Listing"));
const PAAdd = async(() => import("../pages/paycode/Validation"));

//for Prormotions
const PListing = async(() => import("../pages/promotions/Listing"));
const PAdd = async(() => import("../pages/promotions/Validation"));


//for Prormotions
const SLListing = async(() => import("../pages/logs/Listing"));

//for ad more promotions by akshay
const BListing = async(() => import("../pages/morepromotions/Listing"));
const BAdd = async(() => import("../pages/morepromotions/Validation"));

//for public holiday 
const PublicHolidayListing = async(() => import("../pages/holidays/Listing"));
const PublicHolidayAdd = async(() => import("../pages/holidays/Validation"));

//for emp groups
const empGroupListing = async(() => import("../pages/employee_groups/Listing"));
const empGroupAdd = async(() => import("../pages/employee_groups/Validation"));

//for complaints 
const complaintsListing = async(() => import("../pages/complaints/Listing"));
const complaintsAdd = async(() => import("../pages/complaints/Validation"));





const blogsListing = async(() => import("../pages/blog/Listing"));
const blogsAdd = async(() => import("../pages/blog/Validation"));


const rolesListing = async(() => import("../pages/roles/Listing"));
const rolesAdd = async(() => import("../pages/roles/Validation"));





//for master department
const MasterDepartmentListing = async(() => import("../pages/department/Listing"));
const MasterDepartmentAdd = async(() => import("../pages/department/Validation"));
  
   
//my profile
const Mprofile = async(() => import("../pages/myprofile/Add"));



//shifts
const shiftListing = async(() => import("../pages/shifts/Listing"));
const shiftAdd = async(() => import("../pages/shifts/Validation"));

//tax bracekt
const taxBracketListing = async(() => import("../pages/tax_brackets/Listing"));
const taxBracketAdd = async(() => import("../pages/tax_brackets/Validation"));

//shift schedular
const shiftSchedularImport= async(() => import("../pages/shift_schedular/Importschedular"));
const shiftSchedularList= async(() => import("../pages/shift_schedular/Listing"));
const shiftSchedularAdd= async(() => import("../pages/shift_schedular/Validation"));
//payroll line entry
const payrollLineEnrtyAdd = async(() => import("../pages/payroll_line_entry/Validation"));
const payrollLineEntryList = async(() => import("../pages/payroll_line_entry/Listing"));
const payslipImport = async(() => import("../pages/payroll_line_entry/Import_payslip_data"));
const payslipImportExport = async(() => import("../pages/payroll_line_entry/Data"));
const payslippdf =  async(() => import("../pages/payslip/Payslip_pdf"));
//const viewPayslip= async(() => import("../pages/payslip/View_payslip"));
//payslip
const payslipList = async(() => import("../pages/payslip/Listing"));
const viewPayslip= async(() => import("../pages/payslip/View_payslip"));
const Payrolltable= async(() => import("../pages/payslip/Payrolltable"));
//constsnts
const leaveTypeListing = async(() => import("../pages/leave_type/Listing"));
const leaveTypeAdd = async(() => import("../pages/leave_type/Validation"));


//for admin staff
const adminStaffListing = async(() => import("../pages/admin_staff/Listing"));
const adminStaffAdd = async(() => import("../pages/admin_staff/Validation"));
const securityStaffAdd = async(() => import("../pages/admin_staff/ValidationSecurity"));
const securityStaffListing = async(() => import("../pages/admin_staff/ListingSecurity"));

//for product category
const ProductcatgoryListing = async(() => import("../pages/productcategory/Listing"));
const ProductcatgoryAdd = async(() => import("../pages/productcategory/Validation"));


const superadminStaffListing = async(() => import("../pages/superadmin_staff/Listing"));
const superadminStaffAdd = async(() => import("../pages/superadmin_staff/Validation"));



//for product 
const ProductListing = async(() => import("../pages/product/Listing"));
const ProductAdd = async(() => import("../pages/product/Validation"));
const ProductShop = async(() => import("../pages/product/shop"));
const CardAdd = async(() => import("../pages/product/shopproducts"));
const CartAdd = async(() => import("../pages/product/cartinfo"));
const ShopAdd = async(() => import("../pages/product/moreinfo"));


const TopproductAdd = async(() => import("../pages/product/shoptopproducts"));
//for order 
const OrderListing = async(() => import("../pages/order/Listing"));
const OrderAdd = async(() => import("../pages/order/Validation"));


//PublicPointtListing
const PublicPointListing = async(() => import("../pages/points/Listing"));
const PublicPointAdd = async(() => import("../pages/points/Validation"));


//job description
const JobdescListing = async(() => import("../pages/job_descripation/Listing"));
const JobdescAdd = async(() => import("../pages/job_descripation/Validation"));
const JobdescCard = async(() => import("../pages/job_descripation/jpbdesccard"));
const JobpostListing = async(() => import("../pages/jobpost/Listing"));
const JobpostAdd = async(() => import("../pages/jobpost/Validation"));
//const Applyjobs = async(() => import("../pages/careers/Applyjobs"));
const Applist = async(() => import("../pages/jobpost/Applicantlisting"));
const JApplist = async(() => import("../pages/jobpost/JobpostAppListing"));


const IListing = async(() => import("../pages/incident/Listing"));
const IAdd = async(() => import("../pages/incident/Validation"));
const IAddI = async(() => import("../pages/incident/Add"));
const IAddM = async(() => import("../pages/incident/Addmedia"));
const IVidieo = async(() => import("../pages/incident/Vidieo"));

const MsgListing = async(() => import("../pages/empmsg/Chat"));


//for courses and topic
const CourseListing = async(() => import("../pages/course/Listing"));
const CourseAdd = async(() => import("../pages/course/Validation"));

const ChaptorListing = async(() => import("../pages/chaptors/Listing"));
const ChaptorAdd = async(() => import("../pages/chaptors/Validation"));
const TopicAdd = async(() => import("../pages/chaptors/Validationtopic"));
const Vidieo = async(() => import("../pages/chaptors/Vidieo"));
// const IVidieo = async(() => import("../pages/incident/Vidieo"));

//for banners
const BAListing = async(() => import("../pages/banner/Listing"));
const BAAdd = async(() => import("../pages/banner/Validation"));

const avatar = myConstClass.SITE_URL+`img/profile.png`; 
var admin_id= localStorage.getItem('uname'); 
var pro_package = localStorage.getItem('package'); //alert(pro_package);
var manage_promotion_statsu= localStorage.getItem('manage_promotions');
var login_id= localStorage.getItem('login_id');//alert(login_id);
var login_type= localStorage.getItem('login_type');//alert(login_type);
var login_role= localStorage.getItem('role_modules');
var company_name= localStorage.getItem('company_name');
var manage_app= localStorage.getItem('manage_app');//alert(manage_app);
// let url;
let ten_path;
let file_ten_path;
let dashboardR;

let PromotionSuper,SettingsSuper,HRAdminSuper,AdminAppsSuper,RolesSuper,SystemLogsSuper,AdminStaffSuper;

if( window.location.href){
  var str2 = window.location.href.split("/"); 
 if(str2.length<=4 && str2[3]!='super_admin' ){ 
  ten_path = '/pages/auth/page404'; 
  file_ten_path  = "" ;
  dashboardR =  "/auth/404";
 } else {
  var url = window.location.pathname;

  var str = url.split("/");

if(login_role!=null)
{
	

  var module_seen = login_role.split("_");
  
 // alert(module_seen);
  
  
  if(module_seen.includes("4"))
  {
	  PromotionSuper='1';
	  
  }
  else
  {
	    PromotionSuper='0';
  }
  
  
  
  
  
   if(module_seen.includes("1"))
  {
	  SettingsSuper='1';
	  
  }
  else
  {
	    SettingsSuper='0';
  }
  
  if(module_seen.includes("2"))
  {
	  HRAdminSuper='1';
	  
  }
  else
  {
	    HRAdminSuper='0';
  }
  
  
  if(module_seen.includes("3"))
  {
	  AdminAppsSuper='1';
	  
  }
  else
  {
	    AdminAppsSuper='0';
  }
  
  
  
   if(module_seen.includes("5"))
  {
	  RolesSuper='1';
	  
  }
  else
  {
	    RolesSuper='0';
  }
  
  
  //SystemLogsSuper,AdminStaffSuper
   if(module_seen.includes("6"))
  {
	  SystemLogsSuper='1';
	  
  }
  else
  {
	    SystemLogsSuper='0';
  }
  
  
  
  
   if(module_seen.includes("7"))
  {
	  AdminStaffSuper='1';
	  
  }
  else
  {
	    AdminStaffSuper='0';
  }
  
  
  
  
  
  
  //alert(PromotionSuper);
  
  
  
  
  
  
  
}
  if(str[1] && str[1] != 'undefined' &&  str[2] && str[2] != 'undefined' ){
    ten_path = str[1]+'/'+ str[2]; 
    file_ten_path  = "/"+ten_path ;
	//alert(login_type);
	if(login_type=='emp')
	{
		//alert('hii');
		dashboardR = "/"+ten_path+"/employee/profileinfo";
   
	}
	else{
 dashboardR = "/"+ten_path+"/dashboard";
	}
	
  } else if(str[1] && str[1] != 'undefined'){
    ten_path = str[1]; 
    file_ten_path  = "/"+ten_path ;
    dashboardR = "/"+ten_path+"/dashboard";
  }
 }
 
}
//alert(dashboardR);


// if(str[3] && str[4] && str[4] != 'undefined' && str[3] != 'undefined' ){  alert(1);
//   ten_path = str[3]+'/'+ str[4]; 
//   file_ten_path  = "/"+ten_path ;
//   dashboardR = "/"+ten_path+"/dashboard";
// } else if(str[3]){ alert(2);
//   ten_path = str[3];
//   file_ten_path  = "/"+ten_path ;
//   dashboardR = "/dashboard";
// } else { alert(3);
//   ten_path = '';
//   file_ten_path='';
//   dashboardR = "/dashboard";
// }    


// Routes


const landingRoutesNew = {            
  path: "/career/:id",
  name: "Job Frontend Url",
   icon: faHome,
   target:'_blank',
  component: LandingCareer,
  children: null
};
/*const landingRoutesNewCompany = {            
  path: "/careercompany/:id",
  name: "Job Frontend Url",
   icon: faHome,
   target:'_blank',
  component: LandingCareerCompany,
  children: null
};*/

const landingRoutesNewCompany = {            
  path: "/careercompany/:id",
  name: "Career",
   icon: faHome,
   target:'_blank',
  component: LandingCareerCompany,
  children: null
};
let landingRoutesNewCompanyEmp;
if(login_type=='emp'){
landingRoutesNewCompanyEmp = {            
  path: "/careercompany/"+company_name,
  name: "Career",
   icon: faHome,
   target:'_blank',
  component: LandingCareerCompany,
  children: null
};
}
else{
	landingRoutesNewCompanyEmp = {
        path: "x",
        };
}
let landingRoutes;
if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'){
 landingRoutes = {            
  path: file_ten_path+"/careers",
  name: "Job Frontend Url",
   icon: faHome,
   target:'_blank',
  component: Landing,
  children: null
};

} else {
       landingRoutes = {
        path: "x",
        };

     }



 let dashboardRoutes;
if(login_type!='emp')
{
 dashboardRoutes = {
  path:dashboardR,
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Default,
  children: null
};
}
else{
	 dashboardRoutes = {
  path:file_ten_path+"/employee/profileinfo",
  name: "My Profile",
  header: "Main",
  icon: faUserTie,
  component: EProfile,
  children: null
};
}
const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: faDesktop,
  children: [
    {
      path: "/layouts/sidebar-left",
      name: "Left Sidebar",
      component: SidebarLeft
    },
    {
      path: "/layouts/sidebar-right",
      name: "Right Sidebar",
      component: SidebarRight
    },
    {
      path: "/layouts/theme-modern",
      name: "Modern Theme",
      component: ThemeModern
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-dark",
      name: "Dark Theme",
      component: ThemeDark
    },
    {
      path: "/layouts/theme-light",
      name: "Light Theme",
      component: ThemeLight
    }
  ]
};

const uiRoutes = {
  path: "/ui",
  name: "User Interface",
  header: "Elements",
  icon: faFlask,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/ui/general",
      name: "General",
      component: General
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals
    },
    {
      path: "/ui/notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography
    }
  ]
};




const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: faChartPie,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts
    }
  ]
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: faCheckSquare,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts
    },
    {
      path: "/forms/basic-elements",
      name: "Basic Elements",
      component: BasicElements
    },
    {
      path: "/forms/advanced-elements",
      name: "Advanced Elements",
      component: AdvancedElements
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors
    },
    {
      path: "/forms/validation",
      name: "Validation",
      component: Validation
    }
  ]
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: faTable,
  children: [
    {
      path: "/tables/bootstrap",
      name: "Bootstrap",
      component: BootstrapTables
    },
    {
      path: "/tables/advanced-tables",
      name: "Advanced",
      component: AdvancedTables
    }
  ]
};

//for signup req and manage employee modules according to  admin 
//if super admin
// const signupRoutes = {
//   path: "/signup/listing",
//   name: "Signup Requests",
//   icon: faIdCard,
//   component: SListing,
//   children: null
// };

let signupRoutes;
/*if(admin_id ==1 && login_type!='super_admin' || login_type!='emp'){
  signupRoutes ={
    path: "x",
  };
} else if(admin_id ==null){
  
} else {
  signupRoutes ={
    path: file_ten_path+"/signup/listing",
    name: "Signup Requests",
    icon:faUserPlus,
    component: SListing,
    children: null
  };
}
*/




 if(admin_id == 1 && login_id == 1 || login_type=='super_admin' && admin_id == 1){  
 
 signupRoutes ={
    path: file_ten_path+"/signup/listing",
    name: "Signup Requests",
    icon:faUserPlus,
    component: SListing,
    children: null
  };
 }
 else{
	 signupRoutes ={
    path: "x",
  };
 }





















// const signupRoutes = (admin_id !=0 ) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/signup/listing",
//   name: "Signup Requests",
//   icon:faIdCard,
//   component: SListing,
//   children: null
// };

 let logs;
if(login_type =='hr_admin' || login_type =='sub_admin' ||login_type =='super_admin' && admin_id ==1 && login_id==1 || login_type =='super_admin' && login_id !=1 && SystemLogsSuper=='1'){
 logs =  {
  path:  file_ten_path+"/logs/listing",
  name: "System Logs",
  icon:faFolderOpen,
  component: SLListing,
  children: null
};
 }
 else{
	 logs = {
    path: "x",
  }
 }
 
 
 
 let profile;
 if(login_type =='emp'){
 profile =  {
path: file_ten_path+"/employee/add",
      name: "All Employee",
      icon: faUserTie,
      component:  EListing,
      children: null
 
 };
 }
 else{
	 profile = {
    path: "x",
  }
 }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
// if(admin_id !=0){
// logs =  {
//   path:  file_ten_path+"/logs/listing",
//   name: "System Logs",
//   icon:faFolderOpen,
//   component: SLListing,
//   children: null
// };
// } else if(admin_id == null){
//   logs =  {
//     path:  file_ten_path+"/logs/listing",
//     name: "",
//     icon:'',
//     component: Default,
//     children: null
//   };
// } else {
// logs = {
//   path:  file_ten_path+"/logs/listing",
//   name: "System Logs",
//   icon:faFolderOpen,
//   component: SLListing,
//   children: null
// };
// }
// const logs = (admin_id !=0 ) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/logs/listing",
//   name: "System Logs",
//   icon:faIdCard,
//   component: SLListing,
//   children: null
// };

//if hr admin

let empRoutes;
if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'){
//for employee 
 empRoutes = {
  path: file_ten_path+"/pages",
  name: "Employees",
  icon: faUserFriends,
  children: [
    {
      path: file_ten_path+"/employee/listing",
      name: "All Employee",
      icon: faUserFriends,
      component:  EListing,
      children: null
    },
    {
      path: file_ten_path+"/employee_groups/listing",
      name: "Employee Groups",
      icon: faVolumeUp,
      component:  empGroupListing,
      children: null
    },
    {
      path: file_ten_path+"/complaints/listing",
      name: "Complaints",
      icon: faVolumeUp,
      component:  complaintsListing,
      children: null
    }
  
  ]
};
} else if(admin_id !=1 && login_type!='super_admin' && pro_package == 0 && login_type!='emp'){
  empRoutes = {
    path: file_ten_path+"/pages",
    name: "Employees",
    icon: faUserFriends,
    children: [
      {
        path: file_ten_path+"/employee/listing",
        name: "All Employee",
        icon: faUserFriends,
        component:  EListing,
        children: null
      },
    ]
  };
} 
else if(login_type=='emp' && pro_package != 1){
  empRoutes = {
     path: file_ten_path+"/complaints/listing",
      name: "Complaints",
      icon: faVolumeUp,
      component:  complaintsListing,
      children: null
  };
}

else {
  empRoutes = {
    path: "x",
  }
}



let ComplaintRoutes;

if(login_type=='emp'){
  ComplaintRoutes = {
     path: file_ten_path+"/complaints/listing",
      name: "Complaints",
      icon: faExclamationTriangle,
      component:  complaintsListing,
      children: null
  };
}

else {
  ComplaintRoutes = {
    path: "x",
  }
}


























//for settings
let SettingRoutes;
let SettingRoutesEmp;
if(login_type =='emp'){
  SettingRoutesEmp = {
    path: file_ten_path+"/job_descripation/listing",
      name: "My Job Description",
      icon: faBusinessTime,
      component:  JobdescListing,
      children: null
    /*  {
        path: file_ten_path+"/department/listing",
        name: "Master Department",
        icon: faVolumeUp,
        component:  MasterDepartmentListing,
        children: null
      }, */
   // ]
  };
}

//let leaveRoutes;
 
else {
   SettingRoutesEmp = {
    path: "x",
    };
}











if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'){
 SettingRoutes = {
  path: file_ten_path+"/pages",
  name: "Settings",
  icon: faCog,
  children: [
    {
      path: file_ten_path+"/organization/listing",
      name: "Organization",
      icon: faLandmark,
      component:  OListing,
      children: null
    },
    {
      path: file_ten_path+"/holidays/listing",
      name: "Public Holidays",
      icon: faVolumeUp,
      component:  PublicHolidayListing,
      children: null
    },
    {
      path: file_ten_path+"/department/listing",
      name: "Master Department",
      icon: faVolumeUp,
      component:  MasterDepartmentListing,
      children: null
    },
    {
      path: file_ten_path+"/points/listing",
      name: "Point Declaration",
      icon: faVolumeUp,
      component:  PublicPointListing,
      children: null
    },
  {
      path: file_ten_path+"/job_descripation/listing",
      name: "Job Description",
      icon: faBusinessTime,
      component:  JobdescListing,
      children: null
    },
  ]
};
}  else if(admin_id !=1 && login_type!='super_admin' && pro_package == 0 && login_type!='emp'){
  SettingRoutes = {
    path: file_ten_path+"/pages",
    name: "Settings",
    icon: faCog,
    children: [
      {
        path: file_ten_path+"/organization/listing",
        name: "Organization",
        icon: faLandmark,
        component:  OListing,
        children: null
      },
      {
        path: file_ten_path+"/department/listing",
        name: "Master Department",
        icon: faVolumeUp,
        component:  MasterDepartmentListing,
        children: null
      },
    ]
  };
} else if(admin_id ==1 && login_id==1 || login_type=='super_admin' && login_type!='emp' && SettingsSuper=='1' && login_id!=1){
  SettingRoutes = {
    path: file_ten_path+"/pages",
    name: "Settings",
    icon: faCog,
    children: [
      {
        path: file_ten_path+"/organization/listing",
        name: "Organization",
        icon: faLandmark,
        component:  OListing,
        children: null
      },
    /*  {
        path: file_ten_path+"/department/listing",
        name: "Master Department",
        icon: faVolumeUp,
        component:  MasterDepartmentListing,
        children: null
      }, */
    ]
  };
}
else {
  SettingRoutes = {
    path: "x",
}
}


// const empRoutes = {
//   path: "/employee/listing",
//   name: "Employee",
//   icon: faUserFriends,
//   component:  EListing,
//   children: null
//   };

  const empRoutes1 = {
    path: "/employee/listing",
    name: "Employee",
    icon: faUserFriends,
    component:  EListing,
    children: [
      {
        path: file_ten_path+"/employee/add",
       // name: "Add",
        component: EAdd
      },
    ]
    };

    //for employee bulk import
// const empBulkRoutes = {
//   path: "/employee_bulk_import/validation",
//   name: "Employee Bulk Import",
//   icon: faUserFriends,
//   component:  EbAdd,
//   children: null
//   };

  let empBulkRoutes;
if(admin_id !=1 || login_type!='super_admin'){
  empBulkRoutes = { 
    path: file_ten_path+"/employee_bulk_import/import",
    name: "Employee Bulk Import",
    icon:faUserFriends,
    component: EbAdd,
    children: null
  }; 
} else if(admin_id ==null){
  empBulkRoutes = {
    path: file_ten_path+"/employee_bulk_import/import",
    name: "",
    icon:'',
    component: Default,
    children: null
  }; 
} else  {
  empBulkRoutes = {
    path: file_ten_path+"/employee_bulk_import/import",
    name: "",
    icon:'',
    children: null
  }
}
    
  
    const orgRoutes = {
      path: file_ten_path+"/organization/listing",
      name: "Organization",
      icon: faLandmark,
      component:  OListing,
      children: null
      };

      const orgRoutes1 = {
        path: "/organization/listing",
        name: "Organization",
        icon: faLandmark,
        component:  OListing,
        children: [
          {
            path: file_ten_path+"/organization/add",
           // name: "Add",
            component: OAdd
          },
        ]
        };



     let attendanceRoutes;
     if(admin_id !=1 && login_type!='super_admin' && login_type!='emp'){
       attendanceRoutes = {
        path: file_ten_path+"/attendance/listing",
        name: "Employee Presence",
        icon: faClock,
        component:  AListing,
        children: null
        };

     } 
	 else if(admin_id !=1 && login_type!='super_admin' && login_type=='emp'){
       attendanceRoutes = {
        path: file_ten_path+"/attendance/listing",
          name: "Attendance",
        icon: faClock,
        component:  AListing,
        children: null
        };

     } 
	 
	 
	 else {
       attendanceRoutes = {
        path: "x",
        };

     }
       
         


          // const attendanceRoutes = {
          //   path: "/attendance/listing",
          //   name: "Attendance",
          //   icon: faClock,
          //   component:  AListing,
          //   children: [
          //     {
          //       path: "/attendance/listing",
          //       name: "Attendance",
          //       component:  AListing,
          //       children: [
          //         {
          //           path: "/attendance/add/:id",
          //          // name: "Add",
          //           component: AAdd
          //         },
          //         {
          //           path: "/attendance/view/:id",
          //          // name: "Add",
          //           component: AView
          //         },
          //       ]
          //     },
          //     {
          //       path: "/absent/listing",
          //       name: "Attendance Absence",
          //       component:  AbsenceListing,
          //       children: [
          //         {
          //           path: "/attendance/add/:id",
          //          // name: "Add",
          //           component: AAdd
          //         },
          //         {
          //           path: "/attendance/view/:id",
          //          // name: "Add",
          //           component: AView
          //         }
          //       ]
          //     }
          //   ]
          //   };
          
          const attendanceRoutes1 = {
            path: file_ten_path+"/attendance/listing",
            name: "Employee Presence",
            icon: faClock,
            component:  AListing,
            children: [
              {
                path: file_ten_path+"/attendance/add",
               // name: "Add",
                component: AAdd
              },
              {
                path: file_ten_path+"/attendance/view",
               // name: "Add",
                component: AView
              },
            ]
            };


//for absent employees  listing
let absentRoutes;
if(admin_id !=1 && login_type!='super_admin' && login_type!='emp'){
   absentRoutes = {
    path: file_ten_path+"/absent/listing",
    name: "Employee Absence",
    component:  AbsenceListing,
    icon: faUserSlash,
    children: null
  };
} else {
  absentRoutes = {
    path: "x",
  };
}

const absentRoutes1 = {
path: file_ten_path+"/absent/listing",
name: "Employee Absence",
icon: faClock,
component:  AbsenceListing,
children: [
{
path: file_ten_path+"/attendance/add/:id",
// name: "Add",
component: AAdd
},
{
path: "/attendance/view/:id",
// name: "Add",
component: AView
},
]
};


//for laeaves
let leaveRoutes;
if(admin_id !=1 && login_type!='super_admin' && login_type!='emp'){
   leaveRoutes = {
    path: file_ten_path+"/leaves/listing",
    name: "Day Leave",
    icon: faCalendarDay,
    component:  LListing,
    children: null
    };
} 
else if(login_type=='emp'){
   leaveRoutes = {
    path: file_ten_path+"/leaves/listing",
    name: "My Leaves",
    icon: faCalendarDay,
    component:  LListing,
    children: null
    };
}
else {
   leaveRoutes = {
    path: "x",
    };
}


  const leaveRoutes1 = {
    path: file_ten_path+"/leaves/listing",
    name: "Day Leave",
    icon: faCalendarDay,
    component:  LListing,
    children: [
      {
        path: file_ten_path+"/leaves/add",
       // name: "Add",
        component: LAdd
      },
    ]
    };


    //for Announcements
    let announcementRoutes;
    if(admin_id !=1 && login_type!='super_admin'){ 
      announcementRoutes = {
        path: file_ten_path+"/announcements/listing",
        name: "Announcements",
        icon: faVolumeUp,
        component:  AnListing,
        children: null
        };
    } else {
      announcementRoutes = {
        path: "x",
        };
    }


  const announcementRoutes1 = {
    path: file_ten_path+"/announcements/listing",
    name: "Announcements",
    icon: faVolumeUp,
    component:  AnListing,
    children: [
      {
        path: file_ten_path+"/announcements/add",
       // name: "Add",
        component: AnAdd
      },
    ]
    };

   
   
   
   
   
    //for Adminapp
  /*  let adminappRoutes;
    if(admin_id !=1 || login_type!='super_admin'){ 
      adminappRoutes = {
        path: file_ten_path+"/adminapp/listing",
        name: "Admin App",
        icon: faMobile,
        component:  AdListing,
        children: null
        };
    } else {
      adminappRoutes = {
        path: "x",
        };
    }*/


let promotionRoutes;
if(admin_id !=1 && login_type!='super_admin' && manage_promotion_statsu == 1 && login_type!="emp" ){
  promotionRoutes = {
    path: file_ten_path+"/promotions/listing",
    name: "Promotions",
    icon:faImages,
    component: PListing,
    children: null
  };
  } else if(admin_id !=1 && login_type!='super_admin'  && manage_promotion_statsu == 0 && login_type!="emp" ){
  promotionRoutes = {
    path:"x",
  };
  }
   else if(admin_id == null || login_type=="emp" || login_type=='super_admin' && admin_id ==1 && PromotionSuper=='0'&& login_id!=1){
  promotionRoutes = {
    path:"x",
  };
}  
  else if(login_type=='super_admin' && admin_id ==1 && PromotionSuper=='1'&& login_id!=1)  {
  promotionRoutes = {
    path: file_ten_path+"/promotions/listing",
    name: "Promotions",
    icon:faImages,
    component: PListing,
    children: null
  };
}
 else  {
  promotionRoutes = {
    path: file_ten_path+"/promotions/listing",
    name: "Promotions",
    icon:faImages,
    component: PListing,
    children: null
  };
}
// const promotionRoutes = (admin_id !=0 && admin_id == null) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon:faImages,
//   component: PListing,
//   children: null
// };

  const promotionRoutes1 = {
    path: file_ten_path+"/promotions/listing",
    name: "Promotions",
    icon: faImages,
    component:  PListing,
    children: [
      {
        path: file_ten_path+"/promotions/add",
       // name: "Add",
        component: PAdd
      },
    ]
    };





	let adminappRoutes;
if(admin_id !=1 && login_type!='super_admin' && manage_app == 1  && login_type!='emp' || admin_id ==1 && login_type=='super_admin'
&& AdminAppsSuper=='1' 
){
  adminappRoutes = {
      path: file_ten_path+"/adminapp/listing",
        name: "Admin Apps",
        icon: faMobile,
        component:  AdListing,
        children: null
        };
  } 
  
  else if(admin_id !=1 && login_type!='super_admin' && manage_app == 1  && login_type=='emp'){
  adminappRoutes = {
      path: file_ten_path+"/adminapp/listing",
        name: "Org Apps",
        icon: faMobile,
        component:  AdListing,
        children: null
        };
  }
  
  
  
  
  else if(admin_id !=1 && login_type!='super_admin'  && manage_app == 0 || admin_id ==1 && login_type=='super_admin'
&& AdminAppsSuper!='1' && login_id!=1){
  adminappRoutes = {
    path:"x",
  };
} else if(admin_id == null){
  adminappRoutes = {
    path:"x",
  };
}  else {
  adminappRoutes = {
    path: file_ten_path+"/adminapp/listing",
        name: "Admin Apps",
        icon: faMobile,
        component:  AdListing,
        children: null
  };
}

  const adminappRoutes1 = {
    path: file_ten_path+"/adminapp/listing",
    name: "Admin App",
    icon: faVolumeUp,
    component:  AdListing,
    children: [
      {
        path: file_ten_path+"/adminapp/add",
       // name: "Add",
        component: AdAdd
      },
    ]
    };
   
   
   
   
   
   
   
   
   
   
   
     //public holiday menu 
    const holidayRoutes = {
      path: file_ten_path+"/holidays/listing",
      name: "Public Holidays",
      icon: faVolumeUp,
      component:  PublicHolidayListing,
      children: null
      };
      const holidayRoutes1 = {
        path: "/holidays/listing",
        name: "Public Holidays",
        icon: faVolumeUp,
        component:  PublicHolidayListing,
        children: [
          {
            path: file_ten_path+"/holidays/add",
           // name: "Add",
            component: PublicHolidayAdd
          },
        ]
        };

        //public holiday menu 
        const DepartmentRoutes = {
          path: file_ten_path+"/department/listing",
          name: "Department Master",
          icon: faVolumeUp,
          component:  MasterDepartmentListing,
          children: null
          };
          const DepartmentRoutes1 = {
            path: "/department/listing",
            name: "Department Master",
            icon: faVolumeUp,
            component:  MasterDepartmentListing,
            children: [
              {
                path: file_ten_path+"/department/add",
               // name: "Add",
                component: MasterDepartmentAdd
              },
            ]
            };

    // employee group menu 
    const empGroupRoutes = {
      path: file_ten_path+"/employee_groups/listing",
      name: "Employee Groups",
      icon: faVolumeUp,
      component:  empGroupListing,
      children: null
      }; 
      const empGroupRoutes1 = {
        path: file_ten_path+"/employee_groups/listing",
        name: "Employee Groups",
        icon: faVolumeUp,
        component:  empGroupListing,
        children: [
          {
            path: file_ten_path+"/employee_groups/add",
           // name: "Add",
            component: empGroupAdd
          },
        ]
        };

    
       // COMPLAINTS menu 
    const complaintsRoutes = {
      path: file_ten_path+"/complaints/listing",
      name: "Complaints",
      icon: faVolumeUp,
      component:  complaintsListing,
      children: null
      }; 
      const complaintsRoutes1 = {
        path: file_ten_path+"/complaints/listing",
        name: "Complaints",
        icon: faVolumeUp,
        component:  complaintsListing,
        children: [
          {
            path: file_ten_path+"/complaints/add",
           // name: "Add",
            component: complaintsAdd
          },
        ]
        };











    
    // const promotionRoutes = (admin_id !=0 ) ? {
    //   path: "/",
    //   name: "",
    //   icon: '',
    //   component:  '',
    //   children: null
    //   } : {
    //     path: "/promotions/listing",
    //     name: "Manage Promotions",
    //     icon: faImages,
    //     component:  PListing,
    //     children: null
    //     };

// let promotionRoutes;
// if(admin_id == 0){
// //for Promotions
//  promotionRoutes = {
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon: faImages,
//   component:  PListing,
//   children: null
//   };
// } else {
//   //for Promotions
//  promotionRoutes = {
//   path: "/",
//   name: "",
//   icon: '',
//   component:  '',
//   children: null
//   };
// }


    












let bannerRoutes;
if(admin_id !=1 && login_type!='super_admin'   ){
  bannerRoutes = {
    path: file_ten_path+"/banner/listing",
    name: "Banners",
    icon:faImages,
    component: BAListing,
    children: null
  };
  } else if(admin_id !=1 && login_type!='super_admin' ){
  bannerRoutes = {
    path:"x",
  };
} else if(admin_id == null){
  bannerRoutes = {
    path:"x",
  };
}  else {
  bannerRoutes = {
    path: file_ten_path+"/banner/listing",
    name: "Banners",
    icon:faImages,
    component: BAListing,
    children: null
  };
}
// const promotionRoutes = (admin_id !=0 && admin_id == null) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon:faImages,
//   component: PListing,
//   children: null
// };

  const bannerRoutes1 = {
    path: file_ten_path+"/banner/listing",
    name: "Banners",
    icon: faImages,
    component:  BAListing,
    children: [
      {
        path: file_ten_path+"/banner/add",
       // name: "Add",
        component: BAAdd
      },
    ]
    };
























    //for incident media
	let incidentRoutes;
	if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'){
     incidentRoutes = {
      path: file_ten_path+"/incident/listing",
      name: "Incident Report",
      icon:faImages,
      component: IListing,
      children: null
    };
	} else {
       incidentRoutes = {
        path: "x",
        };

     }
	let MsgRoutes;
	if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type != 'emp'){
	
	 MsgRoutes = {
      path: file_ten_path+"/empmsg/listing",
      name: "Employee Admin Msg",
      icon:faCommentAlt,
      component: MsgListing,
      children: null
    };
	
	} 
	else if(login_type == 'emp' && pro_package != 0){
	
	    MsgRoutes = {
      path: file_ten_path+"/empmsg/listing",
      name: "Messages",
      icon:faCommentAlt,
      component: MsgListing,
      children: null
    };
	
	} 
	
	else {
       MsgRoutes = {
        path: "x",
        };

     }
	
	
	
	
	
	
	
    const incidentRoutes1 = {
      path: file_ten_path+"/incident/listing",
      name: "Incident Report",
      icon: faImages,
      component:  IListing,
      children: [
        {
          path: file_ten_path+"/incident/add",
         // name: "Add",
          component: IAdd
        },
      {
          path: file_ten_path+"/incident/addi",
         // name: "Add",
          component: IAddI
        },
      {
          path: file_ten_path+"/incident/addm",
         // name: "Add",
          component: IAddM
        },
       {
          path: file_ten_path+"/incident/vidieo",
         // name: "Add",
          component: IVidieo
        },
      ]
      };
      
      let employetrainingRoutes;
      //for courses and topic   
	  
	  
	  if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'  ){
      employetrainingRoutes = {
        path: file_ten_path+"/pages",
        name: "Employee Training",
        icon: faChalkboardTeacher,
        children: [
          {
            path: file_ten_path+"/course/listing",
            name: "Course",
            icon: faChalkboardTeacher,
            component:  CourseListing,
            children: null
          },
        ]
      };
} else if(login_type=='emp' && pro_package != 0){
	

	
	employetrainingRoutes = {
        //path: file_ten_path+"/pages",
		 path: file_ten_path+"/course/listing",
        name: "Training",
        icon: faChalkboardTeacher,
		component:  CourseListing,
		children: null
      };
     }
	 
	 else{
		 employetrainingRoutes = {
        path: "x",
        };
	 }

      const employetrainingRoutes1 = {
      path: file_ten_path+"/course/listing",
      name: "Course",
      icon: faChalkboardTeacher,
      component:  CourseListing,
      children: [
        {
          path: file_ten_path+"/course/add",
        // name: "Add",
          component: CourseAdd
        },
      {
          path: file_ten_path+"/chaptors/add",
        // name: "Add",
          component:ChaptorAdd
        },
      {
          path: file_ten_path+"/chaptors/addtopic",
        // name: "Add",
          component:TopicAdd
        },
      {
          path: file_ten_path+"/chaptors/listing",
        // name: "Add",
          component:ChaptorListing
        },
      {
          path: file_ten_path+"/chaptors/vidieo",
        // name: "Add",
          component:Vidieo
        },
      /*{
          path: file_ten_path+"/product/add/:id",
        // name: "Add",
          component: ProductAdd
        },
      {
          path: file_ten_path+"/order/add/:id",
        // name: "Add",
          component: OrderAdd
        },*/
      ]
      };
//  const viewRoutes1;






let blogRoutes;
      //for courses and topic  

	  if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp' ){  
      blogRoutes = {
         path: file_ten_path+"/blog/listing",
            name: "Blogs",
            icon: faBlog,
            component:  blogsListing,
            children: null,
      };
} 
 if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type=='emp' ){  
      blogRoutes = {
         path: file_ten_path+"/blog/listing",
            name: "Org Blogs",
            icon: faBlog,
            component:  blogsListing,
            children: null,
      };
} 
else {
       blogRoutes = {
        path: "x",
        };

     }




let rolesRoutes;
      //for courses and topic  

	  if(admin_id == 1 && login_id == 1 || login_type=='super_admin' && admin_id == 1 && RolesSuper=='1'){  
      rolesRoutes = {
         path: file_ten_path+"/roles/listing",
            name: "Roles",
            icon: faBlog,
            component:  rolesListing,
            children: null,
      };
} 

else {
       rolesRoutes = {
        path: "x",
        };

     }









 const rolesRoutes1 = {
     path: file_ten_path+"/roles/listing",
            name: "Blogs",
            icon: faBlog,
            component:  rolesListing,
      children: [
        {
          path: file_ten_path+"/roles/add",
        // name: "Add",
          component: rolesAdd
        },
      
      ]
      };
















      const blogRoutes1 = {
     path: file_ten_path+"/blog/listing",
            name: "Blogs",
            icon: faBlog,
            component:  blogsListing,
      children: [
        {
          path: file_ten_path+"/blog/add",
        // name: "Add",
          component: blogsAdd
        },
      
      ]
      };































const viewRoutes1=   {
  path: "/signup",
  name: "SignUp Requests",
  icon: faIdCard,
  children: [
    {
      path: file_ten_path+"/signup/view",
     // name: "Add",
      component: SView
    },
    {
      path: file_ten_path+"/signup/message",
     // name: "Add",
      component: SMsg
    },
  ]
};

//   const viewRoutes1 = {
//   path: "/signup",
//   name: "SignUp Requests",
//   icon: faTable,
//   children: [
//     {
//       path: "/signup/view/:id",
//      // name: "Add",
//       component: SView
//     },
//     {
//       path: "/signup/message",
//      // name: "Add",
//       component: SMsg
//     },
//   ]
// } 

// let hradminRoutes;
// if(admin_id == 0){
//   //for Promotions
//   hradminRoutes = {
//     path: "/hradmin/listing",
//     name: "Manage HR Admin",
//     icon:faUserCircle,
//     component: HRListing,
//     children: null
//     };
//   } else {
//     //for Promotions
//     hradminRoutes = {
//     path: "/",
//     name: "",
//     icon: '',
//     component:  '',
//     children: null
//     };
//   }

let hradminRoutes;
if(admin_id !=1 && login_type!='super_admin' || admin_id ==1 && login_type=='super_admin' && HRAdminSuper!='1' && login_id!=1){
  hradminRoutes = {
    path: "x",
  }; 
} else if(admin_id ==null){
  hradminRoutes = {
    path: "x",
  }; 
} else  {
  hradminRoutes = {
    path: file_ten_path+"/hradmin/listing",
    name: "HR Admin",
    icon:faUserTie,
    component: HRListing,
    children: null
  }
}
// const hradminRoutes = (admin_id !=0 ) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/hradmin/listing",
//   name: "Manage HR Admin",
//   icon:faUserCircle,
//   component: HRListing,
//   children: null
// };


const hradminRoutes1 = { 
  path: "/hradmin",
  name: "HR Admin",
  icon: faUserCircle,
  children: [
    {
      path: file_ten_path+"/hradmin/add",
     // name: "Add",
      component: HRAdd
    }, 
  ]
}

const measureRoutes1 = {
  path: "/measures",
  name: "Masters",
  icon: faTable,
  children: [
    {
      path: "/measures/add/:id",
     // name: "Add",
      component: Add
    },
    {
      path: "/measures/micro_add/:id",
     // name: "Add",
      component: MicroAdd
    }, 
    {
      path: "/measures/macro_add/:id",
     // name: "Add",
      component: MacroAdd
    }
  ]
}

const measureRoutes = {
  path: "/measures",
  name: "Manage Masters",
  icon: faTable,
  children: [
    {
      path: "/measures/listing",
      name: "Measures",
      component: MListing
    },
    {
      path: "/measures/macro_listing",
      name: "Macronutrients",
      component: MacroListing
    },
    {
      path: "/measures/micro_listing",
      name: "Micronutrients",
      component: MicroListing
    }
  ]
};



  //add more promotions by akshay
  let morepromotionRoutes;
  if(admin_id !=1 && login_type!='super_admin' ){
    morepromotionRoutes = {
      path: "/morepromotions/listing",
      name: "",
      icon:'',
      component: Default,
      children: null
    };
  } else if(admin_id == null){
    morepromotionRoutes = {
      path: "/morepromotions/listing",
      name: "",
      icon:'',
      component: Default,
      children: null
    };
  } else {
    morepromotionRoutes = {
      path: "/morepromotions/listing",
      name: "MorePromotions",
      icon:faImages,
      component: BListing,
      children: null
    };
  }
  
  const morepromotionRoutes1 = {
    path: "/morepromotions/listing",
    name: "MorePromotions",
    icon: faImages,
    component:  PListing,
    children: [
      {
        path: "/morepromotions/add/:id",
       // name: "Add",
        component: BAdd
      },
    ]
    };


    const MYprofile1 = { 
      path: file_ten_path+"/myprofile/add",
      name: "MYprofile",
      icon: faUserCircle,
      children: [
        {
          path: file_ten_path+"/myprofile/Add",
         // name: "Add",
            component: Mprofile 
        }, 
      ]
    }

 

    let shiftsRoutes;
    let import_schedularRoutes;
	let schedularRoutesAdd;
	let employecareerRoutes;
    let payrollLineEntryRoutes;
    let payrollLineEntryRoutes1;
    let payrollRoutes;
    let constantsRoutes;
    let constantsRoutes1;
	let paycodeRoutes1;
	 let DocsRoutes;
    let PointsRoutes;
	let AccessRoutes;
	let PromotionsEmpRoutes;
	let AssetsRoutes;
	let ProductShopRoutes;
	let TotalworkRoutes;
	
    if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'  ){
      // shiftsRoutes = {
      //  path: file_ten_path+"/shifts/listing",
      //  name: "Manage Shifts",
      //  icon: faTable,
      //  component:  shiftListing,
      //  children: null
      //  };
     /*import_schedularRoutes = { 
        path: file_ten_path+"/shift_schedular/Importschedular",
        name: "Employee Bulk Import",
        icon:faClock,
        component: shiftSchedularImport,
        children: null
      }; */
schedularRoutesAdd = { 
        path: file_ten_path+"/shift_schedular/add",
        name: "Employee Bulk Import",
        icon:faClock,
        component: shiftSchedularAdd,
        children: null
      }; 


		
		
		
		PointsRoutes={
       path: "x",
        };
		
		AccessRoutes={
			path:"x",
		};
		
		
		PromotionsEmpRoutes={
			
			path:"x",
		};
		
		AssetsRoutes={
      path: "x",
        };


ProductShopRoutes={
      path: "x",
        };


TotalworkRoutes={
	path: "x",
};







      shiftsRoutes = {
        path: file_ten_path+"/pages",
        name: "Manage Shifts",
        icon: faTable,
        children: [
          {
            path: file_ten_path+"/shifts/listing",
            name: "All Shifts",
            icon: faUserFriends,
            component:  shiftListing,
            children: null
          },
          {
            path: file_ten_path+"/shift_schedular/listing",
            name: "Shift Schedular",
            icon: faClock,
            component:  shiftSchedularList,
            children: null
          },
		  
		  {
		  
		  
		  path: file_ten_path+"/shift_schedular/Importschedular",
        name: "Shift Schedular Import",
        icon:faClock,
        component: shiftSchedularImport,
        children: null
		  
		  
		  
		    },
		  
		  
		  
		  
		  
		  
		  
		  
		  
          
        ]
      };
       
      
      payrollRoutes = {
        path: file_ten_path+"/pages",
        name: "Payroll",
        icon: faMoneyBill,
        children: [
          {
            path: file_ten_path+"/tax_brackets/listing",
            name: "Manage Tax Brackets",
            icon: faTable,
            component:  taxBracketListing,
            children: null
          },
          {
            path: file_ten_path+"/payroll_line_entry/listing",
            name: "Payroll Line Entry",
            icon: faFileAlt,
            component:  payrollLineEntryList,
            children: null
          },
           {
            path: file_ten_path+"/payslip/listing",
            name: "Payslip",
            icon: faFileAlt,
            component:  payslipList,
            children: null
          },
		  
        /*{
          path: file_ten_path+"/payslip/Payrolltable",
         // name: "Add",
		 name: "Payroll Table",
            icon: faFileAlt,
          component:Payrolltable,
		   children: null
          
        },*/
        ]
      };

employecareerRoutes = {
  path: file_ten_path+"/pages",
  name: "Employee Career",
  icon: faTable,
  children: [
    {
      path: file_ten_path+"/jobpost/listing",
      name: "All Job Post",
      icon: faUserFriends,
      component:  JobpostListing,
      children: null
    },
    {
      path: file_ten_path+"/jobpost/Applicantlisting",
      name: "Application List",
      icon: faUserFriends,
      component:  Applist,
      children: null
    },
	 
/*	 {
      path: file_ten_path+"/jobpost/listing",
      name: "All Job Post",
      icon: faUserFriends,
      component:  JobpostListing,
      children: null
    }*/
,
   /* {
      path: file_ten_path+"/product/listing",
      name: "Products",
      icon: faVolumeUp,
      component:  ProductListing,
      children: null
    },
    {
      path: file_ten_path+"/order/listing",
      name: "Orders",
      icon: faVolumeUp,
      component:  OrderListing,
      children: null
    }, */
  ]
};

     
   

    } 
	
	
	else if(login_type=='emp' && pro_package != 0 )
	{
		shiftsRoutes = {
        path: file_ten_path+"/shift_schedular/listing",
            name: "My Shift Schedule",
            icon: faClock,
            component:  shiftSchedularList,
            children: null
      };
       schedularRoutesAdd= {
        path: "x",
        };
		
		

		
		
		
      payrollRoutes={
        path: file_ten_path+"/payslip/listing",
            name: "My Payslips",
            icon: faFileAlt,
            component:  payslipList,
            children: null
        };
		
		
		 AccessRoutes={
        path: file_ten_path+"/employee/Acesscode",
            name: "Access Code",
            icon: faCompressArrowsAlt,
            component: Acesscode ,
            children: null
        };
		PromotionsEmpRoutes={
        path: file_ten_path+"/employee/morepromotions",
            name: "Promotions",
            icon: faImages,
            component: EListingPromotions ,
            children: null
        };
		
		
		PointsRoutes={
        path: file_ten_path+"/employee/ListingPoints",
            name: "My points",
            icon: faTrophy,
            component: EListingPoints ,
            children: null
        };
		
		
		
		AssetsRoutes={
        path: file_ten_path+"/employee/ListingAssts",
            name: "My Assests",
            icon: faTv,
            component:  EListingAssts,
            children: null
        };
		
		
		
		ProductShopRoutes={
        path: file_ten_path+"/product/shop",
            name: "Shop",
            icon: faCartPlus,
            component:  ProductShop,
            children: null
        };
		
		
		TotalworkRoutes={
        path: file_ten_path+"/attendance/checkinout",
            name: "Check In/Out",
            icon: faMapMarkerAlt,
            component:  BView,
            children: null
        };
		
		
		
		
		
		
		
		
		
		
		employecareerRoutes= {
     path: "x",
};
	}
	

	
	else {
     shiftsRoutes = {
       path: "x",
       };
     schedularRoutesAdd= {
        path: "x",
        };
      payrollRoutes={
        path: "x",
        };
		employecareerRoutes= {
     path: "x",
};







     payrollLineEntryRoutes = {
       path: "x",
       };
     payrollLineEntryRoutes1= {
        path: "x",
        };
      constantsRoutes={
        path: "x",
        };
		paycodeRoutes1= {
     path: "x",
};













     DocsRoutes = {
       path: "x",
       };
     PointsRoutes= {
        path: "x",
        };
      AccessRoutes={
        path: "x",
        };
		PromotionsEmpRoutes= {
     path: "x",
};


     AssetsRoutes = {
       path: "x",
       };
     ProductShopRoutes= {
        path: "x",
        };
      TotalworkRoutes={
        path: "x",
        };
		
     
    }



if(login_type=='emp' && pro_package != 0)
{
	DocsRoutes={
        path: file_ten_path+"/employee/ListingDocs",
            name: "My Documents",
            icon: faPrint,
            component:  EListingDocs,
            children: null
        };
}
else
{
	DocsRoutes={
       path: "x",
        };
}













    payrollLineEntryRoutes1 = { 
      path: file_ten_path+"/payroll_line_entry/listing",
      name: "Payroll Line Entry",
      icon: faFileAlt,
      children: [
        {
          path: file_ten_path+"/payroll_line_entry/add",
         // name: "Add",
          component: payrollLineEnrtyAdd 
        }, 
        {
          path: file_ten_path+"/payroll_line_entry/import_payslip_data",
         // name: "Add",
          component:payslipImport
          
        },
		
		{
          path: file_ten_path+"/payroll_line_entry/data",
         // name: "Add",
          component:payslipImportExport
          
        },
		
		
		
		
		
		
		
		
		
		
		
		
		
        {
          path: file_ten_path+"/payslip/Payslip_pdf/:id",
         // name: "Add",
          component:payslippdf
          
        },
        {
          path: file_ten_path+"/payslip/View_payslip/:id/:id2",
         // name: "Add",
          component:viewPayslip
          
        }
        ,
        {
          path: file_ten_path+"/payslip/Payrolltable",
         // name: "Add",
          component:Payrolltable
          
        }
      ]
    }

 //let constantsRoutes;
if(admin_id !=1 && login_type!='super_admin'  && login_type!='emp'){
    constantsRoutes = {
      path: file_ten_path+"/pages",
      name: "Constants",
      icon: faMoneyBill,
      children: [
        {
          path: file_ten_path+"/leave_type/listing",
          name: "Leave Types",
          icon: faTable,
          component:  leaveTypeListing,
          children: null
        },
		
		{
          path: file_ten_path+"/paycode/listing",
          name: "Pay Codes",
          icon: faTable,
          component:  PAListing,
          children: null
        },
		
		
		
		
		
		
		
		
		
		
		
		
      ]
    };
}
else
{
	 constantsRoutes={
        path: "x",
        };
}


 let superadminStaffRoutes;




  if(admin_id == 1 && login_id ==1 || login_type =='super_admin' && admin_id ==1 && AdminStaffSuper=='1'){
      
    

    superadminStaffRoutes = {
     path:  file_ten_path+"/superadmin_staff/listing",
      name: "Staff",
      icon: faUserFriends,
	 
	  component:  superadminStaffListing,
      //component:  adminStaffListing,
      children: null,
    };

    } else {
     
        superadminStaffRoutes={
          path: "x",
          };
    }





















    let adminStaffRoutes;
    
    if(admin_id !=1 && login_type!='super_admin' && pro_package != 0  && login_type!='emp'){
      
    

    adminStaffRoutes = {
      path: file_ten_path+"/pages",
      name: "Staff",
      icon: faUserFriends,
      //component:  adminStaffListing,
      children: [
        {
         path:  file_ten_path+"/admin_staff/listing",
       //  path:  file_ten_path+"/admin_staff/listing",
          name: "Sub-Admin",
          icon: faUserFriends,
          component:  adminStaffListing,
          children: null
        },
		{
         path:  file_ten_path+"/admin_staff/listingsecurity",
          name: "Security",
          icon: faUserFriends,
          component:  securityStaffListing,
          children: null
        },
      ]
    };

    } else {
     
        adminStaffRoutes={
          path: "x",
          };
    }
    constantsRoutes1 = { 
      path: file_ten_path+"/leave_type/listing",
      name: "Leave Types",
      icon: faUserCircle,
      children: [
        {
          path: file_ten_path+"/leave_type/Add",
         // name: "Add",
          component: leaveTypeAdd 
        },    
      ]
    }
paycodeRoutes1 = { 
      path: file_ten_path+"/paycode/listing",
      name: "Pay Codes",
      icon: faUserCircle,
      children: [
        {
          path: file_ten_path+"/paycode/Add",
         // name: "Add",
          component: PAAdd 
        },    
      ]
    }
    payrollLineEntryRoutes1 = { 
      path: file_ten_path+"/payroll_line_entry/listing",
      name: "Payroll Line Entry",
      icon: faUserCircle,
      children: [
        {
          path: file_ten_path+"/payroll_line_entry/add",
         // name: "Add",
          component: payrollLineEnrtyAdd 
        }, 
        {
          path: file_ten_path+"/payroll_line_entry/import_payslip_data",
         // name: "Add",
          component:payslipImport
          
        },
		{
          path: file_ten_path+"/payroll_line_entry/data",
         // name: "Add",
          component:payslipImportExport
          
        },
        {
          path: file_ten_path+"/payslip/Payslip_pdf/:id",
         // name: "Add",
          component:payslippdf
          
        },
        {
          path: file_ten_path+"/payslip/View_payslip/:id/:id2",
         // name: "Add",
          component:viewPayslip
          
        }
        
      ]
    }

    let adminStaffRoutes1;
    adminStaffRoutes1 = {
      path:  file_ten_path+"/admin_staff/listing",
      name: "Admin Staff",
      icon: faUserFriends,
      component:  adminStaffListing,
      children: [
        {
          path: file_ten_path+"/admin_staff/Add",
         // name: "Add",
          component: adminStaffAdd 
        }, 
{
          path: file_ten_path+"/admin_staff/AddSecurity",
         // name: "Add",
          component: securityStaffAdd 
        },		
      ]
    };


 let superadminStaffRoutes1;
    superadminStaffRoutes1 = {
      path:  file_ten_path+"/superadmin_staff/listing",
      name: "Admin Staff",
      icon: faUserFriends,
      component:  superadminStaffListing,
      children: [
        {
          path: file_ten_path+"/superadmin_staff/Add",
         // name: "Add",
          component: superadminStaffAdd 
        }, 
	
      ]
    };
    // const shiftsRoutes = {
    //   path: "/shifts/listing",
    //   name: "Manage Shifts",
    //   icon: faTable,
    //   component:  shiftListing,
    //   children: null
    //   };
    
     //shift add
    const shiftsRoutes1 = {
      path: file_ten_path+"/shifts/listing",
      name: "Manage Shifts",
      icon: faTable,
      component:  shiftListing,
      children: [
        {
          path: file_ten_path+"/shifts/add",
        // name: "Add",
          component: shiftAdd
        },
      ]
      };
    
    //shift listing
      // const taxBracketRoutes = {
      //   path: "/tax_brackets/listing",
      //   name: "Manage Tax Brackets",
      //   icon: faList,
      //   component:  taxBracketListing,
      //   children: null
      //   };



        let taxBracketRoutes;
    if(admin_id !=1 && login_type!='super_admin' && pro_package != 0){
      taxBracketRoutes = {
       path: file_ten_path+"/tax_brackets/listing",
       name: "Manage Tax Brackets",
       icon: faTable,
       component:  taxBracketListing,
       children: null
       };

    } else {
      taxBracketRoutes = {
       path: "x",
       };

    }


    //shift add
    const taxBracketRoutes1 = {
      path: file_ten_path+"/tax_brackets/listing",
      name: "Manage Tax Brackets",
      icon: faList,
      component:  taxBracketListing,
      children: [
        {
          path: file_ten_path+"/tax_brackets/add",
          component: taxBracketAdd
        },
      ]
      };
       

    //for employee shops
      let employeshopRoutes;
	  
	  if(admin_id !=1 && login_type!='super_admin' && pro_package != 0 && login_type!='emp'){
       employeshopRoutes = {
        path: file_ten_path+"/pages",
        name: "Employee Shop",
        icon: faTable,
        children: [
          {
            path: file_ten_path+"/productcategory/listing",
            name: "All Product Category",
            icon: faUserFriends,
            component:  ProductcatgoryListing,
            children: null
          } ,
          {
            path: file_ten_path+"/product/listing",
            name: "Products",
            icon: faVolumeUp,
            component:  ProductListing,
            children: null
          },
          {
            path: file_ten_path+"/order/listing",
            name: "Orders",
            icon: faTruck,
            component:  OrderListing,
            children: null
          },
		  bannerRoutes = {
    path: file_ten_path+"/banner/listing",
    name: "Banners",
    icon:faImages,
    component: BAListing,
    children: null
  },
        ]
      };
	  }


else if(login_type=='emp' && pro_package != 0) {
       employeshopRoutes = {
         path: file_ten_path+"/order/listing",
            name: "My Orders",
            icon: faTruck,
            component:  OrderListing,
            children: null
        };

     }







	  else {
       employeshopRoutes = {
        path: "x",
        };

     }
      const employeshopRoutes1 = {
        path: file_ten_path+"/productcategory/listing",
        name: "All Product Category",
        icon: faTable,
        component:  ProductcatgoryListing,
        children: [
          {
            path: file_ten_path+"/productcategory/add",
          // name: "Add",
            component: ProductcatgoryAdd
          },
      {
            path: file_ten_path+"/product/add",
          // name: "Add",
            component: ProductAdd
          },
      {
            path: file_ten_path+"/order/add",
          // name: "Add",
            component: OrderAdd
          },
		   {
            path: file_ten_path+"/product/shopproducts",
          // name: "Add",
            component: CardAdd
          },
		  {
            path: file_ten_path+"/product/shoptopproducts",
          // name: "Add",
            component: TopproductAdd
          },
		  {
            path: file_ten_path+"/product/cartinfo",
          // name: "Add",
            component: CartAdd
          },
		  {
            path: file_ten_path+"/product/moreinfo",
          // name: "Add",
            component: ShopAdd
          },
		  {
        path: file_ten_path+"/banner/add/:id/:id1",
       // name: "Add",
        component: BAAdd
      },
        ]
        };


        //for points declaration
        const pointsRoutes = {
          path: file_ten_path+"/points/listing",
          name: "Point Declaration",
          icon: faTrophy,
          component:  PublicPointListing,
          children: null
          };
    
	
	
	
	
	
          const pointsRoutes1 = {
            path: "/points/listing",
            name: "Point Declaration",
            icon: faVolumeUp,
            component:  PublicPointListing,
            children: [
              {
                path: file_ten_path+"/points/add",
               // name: "Add",
                component: PublicPointAdd
              },
            ]
            };


//for job description


const JDRoutes = {
path: file_ten_path+"/job_descripation/listing",
name: "Job Description",
icon: faBusinessTime,
component:  JobdescListing,
children: null
}; 
const JDRoutes1 = {
  path: file_ten_path+"/job_descripation/listing",
  name: "Complaints",
  icon: faBusinessTime,
  component:  JobdescListing,
  children: [
    {
      path: file_ten_path+"/job_descripation/add",
     // name: "Add",
      component: JobdescAdd
    },
	{
      path: file_ten_path+"/job_descripation/info",
     // name: "Add",
      component: JobdescCard
    },
  ]
  };

  const employecareerRoutes1 = {
    path: file_ten_path+"/jobpost/listing",
    name: "All Job Posts",
    icon: faTable,
    component:  JobpostListing,
    children: [
      {
        path: file_ten_path+"/jobpost/add",
      // name: "Add",
        component: JobpostAdd
      },
	  {
      path: file_ten_path+"/jobpost/JobpostAppListing",
      name: "Application List",
      icon: faUserFriends,
      component:  JApplist,
      children: null
    },
    ]
    }; 
  


// const measureRoutes1 = {
//   path: "/signup",
//   name: "Signup Requests",
//   icon: faTable,
//   children: [
//     {
//       path: "/signup/add/:id",
//      // name: "Add",
//       component: SAdd
//     },
//   ]
// }








const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: faHeart,
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather
    },
    {
      path: "/icons/ion-icons",
      name: "Ion Icons",
      component: IonIcons
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome
    }
  ]
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: faCalendarAlt,
  component: Calendar,
  children: null
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: faMapMarkerAlt,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: faFile,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/clients",
      name: "Clients",
      component: Clients,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/tasks",
      name: "Tasks",
      component: Tasks
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};


const landingRoutesCareer = { 

 path: "/careers/:id",
  name: "Careers",
  component: Careers,
};



const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/signup",
      name: "Sign Up",
      component: SignUp
    },
	
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/reset/:id/:id",
      name: "Reset",
      component: Reset
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    },
	/*{path: "/auth/careers/:id",
     name: "Careers",
	component: Careersa},*/
	{
      path: "/auth/reset/:id/:id",
      name: "Reset",
      component: Reset
    },
	
  ]
};

const documentationRoutes = {              
  path: "/documentation",
  name: "Getting Started",
  header: "Extras",
  icon: faBook,
  component: Documentation,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  layoutRoutes,
  uiRoutes,
  chartRoutes,
  formRoutes,
  tableRoutes,
  iconRoutes,
  calendarRoutes,
  mapRoutes,
  pageRoutes,
  documentationRoutes,
  privateRoutes,
  MYprofile1,
  signupRoutes,viewRoutes1,hradminRoutes1,hradminRoutes,empRoutes,empRoutes1,orgRoutes,orgRoutes1,leaveRoutes,leaveRoutes1,announcementRoutes,announcementRoutes1,
  attendanceRoutes,attendanceRoutes1,promotionRoutes,promotionRoutes1,logs,holidayRoutes, holidayRoutes1,empGroupRoutes,empGroupRoutes1,complaintsRoutes,complaintsRoutes1,
  morepromotionRoutes,morepromotionRoutes1
  ,empBulkRoutes,absentRoutes,absentRoutes1,,shiftsRoutes,shiftsRoutes1,taxBracketRoutes,taxBracketRoutes1,schedularRoutesAdd,payrollLineEntryRoutes1,payrollRoutes,
  ,DepartmentRoutes,DepartmentRoutes1,constantsRoutes1,constantsRoutes,adminStaffRoutes,adminStaffRoutes1,superadminStaffRoutes,superadminStaffRoutes1,
  employeshopRoutes,employeshopRoutes1,pointsRoutes1,pointsRoutes,AccessRoutes,PromotionsEmpRoutes,
  JDRoutes,JDRoutes1,employecareerRoutes,employecareerRoutes1,
  ,incidentRoutes1,incidentRoutes,MsgRoutes,paycodeRoutes1,profile,ComplaintRoutes,
  employetrainingRoutes,employetrainingRoutes1,blogRoutes,blogRoutes1,adminappRoutes,adminappRoutes1,bannerRoutes,bannerRoutes1
,DocsRoutes,PointsRoutes,AssetsRoutes,ProductShopRoutes,TotalworkRoutes,rolesRoutes,rolesRoutes1
];

// Landing specific routes
export const landing = [landingRoutes,landingRoutesNew,landingRoutesNewCompany];

// Auth specific routes
export const page = [authRoutes,landingRoutesNew,landingRoutesNewCompany];
// export const measure = [measureRoutes];

// All routes
export default [ 
//landingRoutesNewCompany,
dashboardRoutes,
TotalworkRoutes,
attendanceRoutes,
   absentRoutes,
leaveRoutes,
 empRoutes,
   adminStaffRoutes,
   SettingRoutes,
   constantsRoutes,
 announcementRoutes,
PromotionsEmpRoutes,
AccessRoutes,
 blogRoutes,
 signupRoutes,
 hradminRoutes,
  
   adminappRoutes,
 ComplaintRoutes,
 
 MsgRoutes,
 
 PointsRoutes,
 SettingRoutesEmp,
 
 
 
 
 
 
 
  
  ,DocsRoutes,AssetsRoutes,
  
  
   payrollRoutes,
  
   shiftsRoutes,
    employeshopRoutes,
  ProductShopRoutes,
   
  
  //pageRoutes,
  //authRoutes,
  //uiRoutes,
  //chartRoutes,
  //formRoutes,
  //mRoutes,
   employetrainingRoutes,

   
  
  
  
  
  
   
  
   employecareerRoutes,
   landingRoutes,
   incidentRoutes,
  
   
  //  taxBracketRoutes,
  //  orgRoutes,   
  //  holidayRoutes,
  //  empGroupRoutes,
  //  complaintsRoutes,
  // pointsRoutes,
   promotionRoutes,
  // bannerRoutes,
   
  // empBulkRoutes,
     rolesRoutes,
	 superadminStaffRoutes,
   logs,
   landingRoutesNewCompanyEmp,
  // profile,
  //  empRoutes1,
  //  morepromotionRoutes,
 // iconRoutes,
  //calendarRoutes,
  //mapRoutes,
  //documentationRoutes,
  //layoutRoutes
];

