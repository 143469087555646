import React , { PureComponent } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery'; 
import { toastr } from "react-redux-toastr";
import { createBrowserHistory } from 'history';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  CustomInput
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";



import avatar from "../../assets/img/avatars/avatar.jpg";
// const image = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;
// const image2 = `http://localhost/workzone/backend/img/clock.png`;
// const image3 = `http://localhost/workzone/backend/img/logo2.png`;

// const image  = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;
// const image2 = `https://alpha.workzoneplus.com/backend/img/clock.png`;
// const image3 = `https://alpha.workzoneplus.com/img/logo2.png`;


// const image  = `https://workzonepro.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;
// const image2 = `https://workzonepro.workzoneplus.com/backend/img/clock.png`;
// const image3 = `https://workzonepro.workzoneplus.com/img/logo2.png`;


// const {logout} = props.location.state;
class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hrm:'',
      showPassword: false,
      toDashboard: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
	  complaint_against:[],
    };
    this.login = this.login.bind(this);
  }


  onFormSubmit = e => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    //new changes
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`login/verifyLogin/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
      
      if(response == 2){ 
        toastr.error(
           'Error',
           "Please enter email..!",
           this.options
         )
     }else if(response == 3){
       toastr.error(
           'Error',
           "Please enter password ..!",
           this.options
         )
     }
     else if(response == 4){
      toastr.error(
          'Error',
          "Login Failed ...!",
          this.options
        )
    }else if(response !== false){
		
		
		
		
		
		
		
		 
		
		
		
		
		
		
		
		
		
		
      var sarray = response.split('-'); 
	  localStorage.setItem('login_type', sarray['8']);
	  localStorage.setItem('role_modules', sarray['9']);
	  localStorage.setItem('manage_app', sarray['6']);
      localStorage.setItem('login_id', sarray['7']);
      localStorage.setItem('package', sarray['4']);
      localStorage.setItem('manage_promotions', sarray['3']);
      localStorage.setItem('uname', sarray['2']);
      localStorage.setItem('utype', sarray['1']);
      localStorage.setItem('session_id', sarray['0']);
	  localStorage.setItem('company_name', sarray['10']);
	  
	  
	  
	 
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
      this.setState(() => ({
        toDashboard: true
      }))   
    }
    })
    .catch(); 
  }
  componentDidMount(){
    // encodeURIComponent(myUrl)
    fetch(myConstClass.BASE_URL+'login/get_hrm/'+window.location.href)
    .then(response => response.json())
    .then(response =>{this.setState({hrm: response})}).catch(err => console.log(err))
     
  
      }
  login(e) {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    
    fetch(myConstClass.LOGIN_URL+`login/auth/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
     
      if(response == 2){ 
        toastr.error(
           'Error',
           "Please enter email..!",
           this.options
         )
     }else if(response == 3){
       toastr.error(
           'Error',
           "Please enter password ..!",
           this.options
         )
     } else if(response == 4){
      toastr.error(
          'Error',
          "Incorrect Credentials ...!",
          this.options
        )
    }else if(response == 5){
      toastr.error(
          'Error',
          "Invalid Password ...!",
          this.options
        )
    } else if(response == 6){
      toastr.error(
          'Error',
          "Invalid Email ID ...!",
          this.options
        )
    }  else if(response == 7){
      toastr.error(
          'Error',
          "Login Unsuccessful ..!",
          this.options
        )
    } else if(response !== false){ 
      var sarray = response.split('-');
      var url = sarray['5'];
    //  alert(sarray['2']);
	//alert(sarray['8']);alert(sarray['7']);
	localStorage.setItem('login_type', sarray['8']);
	localStorage.setItem('role_modules', sarray['9']);
      localStorage.setItem('login_id', sarray['7']);
	localStorage.setItem('manage_app', sarray['6']);
      localStorage.setItem('url', sarray['5']);
      localStorage.setItem('package', sarray['4']);
      localStorage.setItem('manage_promotions', sarray['3']);
      localStorage.setItem('uname', sarray['2']);
      localStorage.setItem('utype', sarray['1']);
      localStorage.setItem('session_id', sarray['0']);
  localStorage.setItem('company_name', sarray['10']);
      this.setState(() => ({
        toDashboard: true
      }))   
    } else {
     toastr.error(
       'Error',
       "Login Unsuccessful ..!",
       this.options
     )
   }
    })
    .catch();   
  }

  render() {
  // var url = window.location.href;
  // var str = url.split("/");
  // let ten_path;
  // let file_ten_path;
  // if(str[4] != '' && str[3] != ''){
  // ten_path = str[3]+'/'+ str[4];
  // file_ten_path  =  "/"+ten_path+'/dashboard'; 
  // } else {
  // ten_path = '';
  // file_ten_path='/dashboard';
  // }    



  let ten_path;
  let file_ten_path;
  let dashboardR;
  let path1;
  let path2;
  let path3;
  let path4;
  // let url;

  var tenant_url = localStorage.getItem('url'); 
var login_type= localStorage.getItem('login_type');//alert(login_type);
var company_name= localStorage.getItem('company_name');
var joburl= localStorage.getItem('joburl');
if(tenant_url){
	if(login_type=='emp')
	{
		
		if(joburl=='1')
	{
		 file_ten_path =myConstClass.CARREERCOMPANY_URL+'/'+company_name;
		
		}
	else{
		
		
		   file_ten_path = tenant_url+'attendance/checkinout';
		   }
	}
	else{
		file_ten_path = tenant_url+'dashboard';
	}
  
} else {
  var url = window.location.pathname;
  var str = url.split("/");
  if(str[1]== 't'){
    if(str[1] && str[1] != 'undefined' ){
      path1 = str[1] +'/';
    }
    if(str[2] && str[2] != 'undefined' && str[2] != 't' ){
      path2 = str[2] +'/';
    }
    if(str[3] && str[3] != 'undefined' ){
      path3 = str[3];
    } else {
      path3 = '';
    }
  
    // alert("A- "+path1+'  '+path2+'  '+path3);
    file_ten_path = path2+path3+'dashboard';
  } else {
    if(str[1] && str[1] != 'undefined' ){
      path1 = str[1] +'/';
     
    }
    
    if(str[2] && str[2] != 'undefined' ){
      path2 = str[2];
    } else {
      path2 = 'index.php';
    }
    
    if(str[3] && str[3] != 'undefined' ){
      path3 = str[3]+'/';
    } else {
      path3 = '';
    }
    
    if(str[4] && str[4] != 'undefined' ){
      path4 = str[4]+'/';
    } else {
      path4 = '';
    }
  
    file_ten_path =path1+path2+'/'+path3+path4+'dashboard';
  }


}

 




  // if(str[1] && str[1] != 'undefined' &&  str[2] && str[2] != 'undefined' ){
  //   ten_path = str[1]+'/'+ str[2]; 
  //   file_ten_path  = "/"+ten_path ;
  //   dashboardR = "/"+ten_path+"/dashboard";
  // } else if(str[1] && str[1] != 'undefined'){
  //   ten_path = str[1]; 
  //   file_ten_path  = "/"+ten_path ;
  //   dashboardR = "/"+ten_path+"/dashboard";
  // }

  
  
  if (this.state.toDashboard === true) {
    window.location.href = file_ten_path; 
    return <Redirect to='/dashboard' />
  }
    const {hrm} = this.state;
  
return(
  <React.Fragment>
    <div className="text-center mt-4">   
    </div>
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
          {/* <img src={image2} style={{'width':'40px','height':'40px'}}></img> */}
          {/* <img src={image2} style={{'width':'60px','height':'60px;' }} hspace={20}></img>
          <img src={image} style={{'width':'300px','height':'38px;'}}></img> */}
           <h3>Login</h3>
          </div><br/><br/>
          <AvForm className="form" id="pform"  onSubmit={this.onFormSubmit}>
          <AvGroup>
             <Label for="example">Email</Label>
             <AvInput name="email" type="email" placeholder="Enter your email" value={hrm ? hrm.email:''}/>
         </AvGroup>
         <AvGroup>
             <Label for="example">Password</Label>
             <AvInput name="password" type="password" placeholder="Enter your password" value={hrm ? hrm.password:''}/>
         </AvGroup>
         <small>
             <Link to="/auth/reset-password">Forgot password?</Link>
        </small>
        <center> <div>
        Don't have an account ? <a href="/signup" > Sign Up Now</a>
        </div></center>
        <div className="text-center mt-3">
              <Link to="/dashboard">
               <Button color="primary" type="submit" autofocus="autofocus"  onClick={this.login}>
                  Sign in
                </Button>
              </Link>
            </div><br/>
            <Row>
         </Row>
      </AvForm>
      </div>
   </CardBody>
  </Card>
    {/* <center><img src={image3} style={{'width':'35%'}}></img><br/>   
       <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center> */}
  </React.Fragment>
)
  }
}

export default LogInForm;

