import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import { GitBranch } from "react-feather";

//import moment from 'moment';
// export const BASE_URL = "https://alpha.workzoneplus.com/backend/";
var login_url = localStorage.getItem('url'); 
let ten_path;
let file_ten_path;
let dashboardR;
let path1;
let path2;
let path3;
let path4;
let inter_tenant_path;
let url;
let doc_url;
let inter_tenant_path_hr;
if(login_url){ 
  if(login_url.includes('super_admin')){
    file_ten_path = login_url;
    var super_str = login_url.split("/");
    doc_url = super_str[0]+'/';
    inter_tenant_path = super_str[0]+'/'+super_str[1]+'/';
    inter_tenant_path_hr = super_str[0]+'/'+super_str[1];
  } else {
    file_ten_path = login_url +'index.php/';
    doc_url = login_url +'/';
    var hr_str = file_ten_path.split("/");
    doc_url = hr_str[0]+'/'+hr_str[1]+'/';
    inter_tenant_path =hr_str[0]+'/'+hr_str[1]+'/';
    inter_tenant_path_hr = hr_str[0]+'/'+hr_str[1];
  }

 
 
} else {
  url = window.location.pathname;
  var str = url.split("/");


if(str[1]== 't'){
  if(str[1] && str[1] != 'undefined' ){
    path1 = str[1] +'/';
  }
  if(str[2] && str[2] != 'undefined' && str[2] != 't' ){
    path2 = str[2] +'/';
  }
  if(str[3] && str[3] != 'undefined' ){
    path3 = 'index.php';
  } else {
    path3 = 'index.php';
  }
  file_ten_path = path1+path2+path3;
  inter_tenant_path = path1+path2;
  inter_tenant_path_hr = path1+path2;
  doc_url = path1+path2+'/';
} else {
  if(str[1] && str[1] != 'undefined' ){
    path1 = str[1] +'/';
  }
  
  if(str[2] && str[2] != 'undefined' ){
    path2 = str[2];
  } else {
    path2 = 'index.php';
  }
  file_ten_path = path1+path2+'/';
  inter_tenant_path = '';
  inter_tenant_path_hr = '';
  doc_url = path1+path2+'/';
}
}
//  alert('hii');

// for server
export const LOGIN_URL = "https://saastest.workzoneplus.com/backend/super_admin/index.php/";; 
export const BASE_URL = "https://saastest.workzoneplus.com/backend/"+file_ten_path; 
export const TENANT_PAGE_PATH = inter_tenant_path;
export const TENANT_PAGE_PATH_SUPER_ADMIN = 'super_admin/index.php/';
export const DOCS_URL = "https://saastest.workzoneplus.com/backend/"+doc_url;
export const PROMOTION = inter_tenant_path_hr;
export const SITE_URL = "https://saastest.workzoneplus.com/backend/"; 
export const ADMIN_DOCS_URL = "https://saastest.workzoneplus.com/backend/super_admin";
export const CARREER_URL = "https://saastest.workzoneplus.com/backend/t/";
export const CARREERCOMPANY_URL = "https://saastest.workzoneplus.com/careercompany";
//for localhost
 /*export const SITE_URL = "http://localhost/saastest/backend/"; 
 export const LOGIN_URL = "http://localhost/saastest/backend/super_admin/index.php/";; 
 export const BASE_URL = "http://localhost/saastest/backend/"+file_ten_path; 
 export const TENANT_PAGE_PATH = inter_tenant_path;
 export const TENANT_PAGE_PATH_SUPER_ADMIN = 'super_admin/index.php/';
 export const DOCS_URL = "http://localhost/saastest/backend/"+doc_url;
 export const PROMOTION = inter_tenant_path_hr;
 export const ADMIN_DOCS_URL = "http://localhost/saastest/backend/super_admin";
export const CARREERCOMPANY_URL = "http://localhost:3000/careercompany";
export const CARREER_URL = "https://saastest.workzoneplus.com/backend/t/";
// export const BASE_URL = "https://workzonepro.workzoneplus.com/backend/";*/


/*export const LOGIN_URL = "https://selfhost.workzoneplus.com/backend/super_admin/index.php/";; 
 export const BASE_URL = "https://selfhost.workzoneplus.com/backend/"+file_ten_path; 
 export const TENANT_PAGE_PATH = inter_tenant_path;
 export const TENANT_PAGE_PATH_SUPER_ADMIN = 'super_admin/index.php/';
 export const DOCS_URL = "https://selfhost.workzoneplus.com/backend/"+doc_url;
 export const PROMOTION = inter_tenant_path_hr;
 export const SITE_URL = "https://selfhost.workzoneplus.com/backend/"; 
 export const ADMIN_DOCS_URL = "https://selfhost.workzoneplus.com/backend/super_admin";
 export const CARREER_URL = "https://selfhost.workzoneplus.com/backend/t/";
export const CARREERCOMPANY_URL = "https://selfhost.workzoneplus.com/careercompany";*/
 



