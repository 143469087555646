import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import * as myConstClass from '../constant.js';
import IosPulse from "react-ionicons/lib/IosPulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import routes from "../routes/index";
import './logo.scss';
// import avatar from "../assets/img/avatars/avatar.jpg";
// const avatar = `http://localhost/workzone/backend/img/profile.png`; 
// const logo = `http://localhost/workzone/backend/img/clock_new.png`;
// const zone_logo = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;

// const avatar = `https://alpha.workzoneplus.com/backend/img/profile.png`; 
// const logo = `https://alpha.workzoneplus.com/backend/img/clock_new.png`;
// const zone_logo = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

const avatar = myConstClass.SITE_URL+`img/profile.png`; 
const logo = myConstClass.SITE_URL+`img/clock_new.png`;
const zone_logo = myConstClass.SITE_URL+`img/Work-Zone-Plus-logo.png`;
const pro_zone_logo = myConstClass.SITE_URL+`img/Work-Zone-Pro-logo.png`;
// alert(pro_zone_logo);
const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      empData:[],
      user_id:'',
	  pro_package:'',
    };
  }

  toggle = index => {
    // Collapse all elements
	  // alert('hi');
	  var login_id= localStorage.getItem('login_id');
  var login_type= localStorage.getItem('login_type');
	
	 var admin_id= localStorage.getItem('uname'); 
	 var pro_package= localStorage.getItem('package');
	 this.state.pro_package = pro_package;
	 
   //alert(this.state.pro_package);
  //  if(admin_id){
  //   fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+admin_id)
  //   .then(response => response.json())
  //   .then(response =>{console.log(response);this.setState({empData: response});
	
	//  var pro_package= localStorage.getItem('package');
	//   //this.state.pro_package = pro_package;
	//   this.setState({pro_package: pro_package});
	// //alert(this.state.pro_package);
	
	// })
  //   //  alert(empData);
  //   .catch(err => console.log(err))
  //   // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
  //   // .then(response => response.json())
  //   // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
  // }


if(login_type == 'hr_admin' || login_type == 'super_admin'){
    fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);
	
	
	this.setState({empData: response});
  
    var pro_package= localStorage.getItem('package');
	  //this.state.pro_package = pro_package;
	  this.setState({pro_package: pro_package});
  
  
  
  })
    //  alert(empData);
    .catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
  
 
	  
	  
	  
	//alert(login_type);
	  
	  
	   fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	      //  alert('You have a new message');
			toastr.info(
                        'Message',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))
  
  
  
  
  
  
  
  
  
  
  
  
  }
	else{
    fetch(myConstClass.BASE_URL+'user/getUserInfoE/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);
	
	
	
	
	
	
	//alert(response.JD_Title);
	
	
	
	
	
	this.setState({empData: response});
  
    var pro_package= localStorage.getItem('package');
	  //this.state.pro_package = pro_package;
	  this.setState({pro_package: pro_package});
  
  
  
  })
    //  alert(empData);
    .catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
  
  
  
	  
	  
	  
	//alert(login_type);
	  
	  
	   fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	      toastr.info(
                        'Message',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  }







    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
	 //this.render();
  };

  componentWillMount() {
    /* Open collapse element that matches current url */
	
	var login_id= localStorage.getItem('login_id');
  var login_type= localStorage.getItem('login_type');
	
	 var admin_id= localStorage.getItem('uname'); 
	 var pro_package= localStorage.getItem('package');
	 this.state.pro_package = pro_package;
  // alert ("componentWillMount");
  if(login_type == 'hr_admin' || login_type == 'super_admin'){
    fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
	  
	  
	//alert(login_type);
	  
	  
	 /*  fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	      toastr.info(
                        'Message',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))*/
  }
	else{
    fetch(myConstClass.BASE_URL+'user/getUserInfoE/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
	
	  
	  
	//alert(login_type);
	  
	  
	 /*  fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	       toastr.info(
                        'Message',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))*/
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
  }
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });
  }

  componentDidMount(){
	 // alert ("componentDidMount");
	 var login_id= localStorage.getItem('login_id');
  var login_type= localStorage.getItem('login_type');
    var admin_id= localStorage.getItem('uname'); 
	var pro_package= localStorage.getItem('package');
	this.state.pro_package = pro_package;
  // alert(pro_package);
    if(login_type == 'hr_admin' || login_type == 'super_admin'){
    fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
	  
	   fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	toastr.info(
                        'Message',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))
  }
	else{
    fetch(myConstClass.BASE_URL+'user/getUserInfoE/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
	
	
	  
	  
	  
	//alert(login_type);
	  
	  
	   fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	       toastr.info(
                        'Message',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
  }
  }

  render() { 
    const { sidebar,pro_package } = this.props;
    var admin_id= localStorage.getItem('uname');
    const {empData} = this.state;
    let profile_img,profile_name;

    let c_path;
    let c_icon;
    let c_name;
    let c_badgtext;
    let c_badgcolor;
    let c_main;
	let pro_zone_logo_logo;
      if(empData){
        if(empData.image == '' || empData.image == null && empData.image!=''){
          profile_img=  myConstClass.DOCS_URL+`img/profile.png`;
          profile_name=empData.first_name+' '+empData.last_name;  
        } else {
           profile_img =myConstClass.DOCS_URL+`uploads/profile_images_hr/`+admin_id+`/`+empData.image;
		       profile_name=empData.first_name+' '+empData.last_name;
          // profile_img =`http://localhost/workzone/backend/uploads/banners/`+empData.image;
        }
      } else {
        profile_img=  myConstClass.DOCS_URL+`img/profile.png`; 
		    profile_name='Name';
      }
  
  if(this.state.pro_package == '1')
  {
	  pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Pro-logo.png`;
  }
  else
  {
	  pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Plus-logo.png`;
  }
  
  
  
  
  
  
  
  
  

  
    var admin_id= localStorage.getItem('uname');

    return (
      <nav
        className={classNames(
          "sidebar",
          sidebar.isOpen || "toggled",
          !sidebar.isOnRight || "sidebar-right"
        )}
      >
       <div className="sidebar-content">
        <div class="ScrollStyle_sidebar" id="vertical_scroll_sidebar">
		
		  
       <Link to={'/'+myConstClass.TENANT_PAGE_PATH+"dashboard"} > <img class="dashboar-plus-logo top_logo" src={logo}  style={{width: '40px',height:'40px'}}/>  <img class="dashboar-plus-logo" src={pro_zone_logo_logo} id="pro_logo"  style={{width: '187px',height:'46px'}}/></Link>
		
		  
          <div className="sidebar-user">
            <img
              // src={avatar}
              src={profile_img}
              className="img-fluid rounded-circle mb-2"
              alt="Linda Miller"
            />
            <div className="font-weight-bold">{profile_name}</div>
          </div>
          <ul className="sidebar-nav">
            {routes.map((category, index) => {
            
              if(admin_id==0){ 
                if(category.path == 'x'){
                  c_main ='';
                } else {
                  c_main =   category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={this.state[index]}
                      onClick={() => this.toggle(index)}
                    >
  
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                    name={category.name}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                  />
                  ) ;
                }
               
              } else {
                if(category.path == 'x'){
                  c_main ='';
                } else {
                c_main =   category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={this.state[index]}
                    onClick={() => this.toggle(index)}
                  >

                    {category.children.map((route, index) => (
                      <SidebarItem
                        key={index}
                        name={route.name}
                        to={route.path}
                        badgeColor={route.badgeColor}
                        badgeText={route.badgeText}
                      />
                    ))}
                  </SidebarCategory>
                ) : (
                  <SidebarItem
                  name={category.name}
                  to={category.path}
                  icon={category.icon}
                  badgeColor={category.badgeColor}
                  badgeText={category.badgeText}
                />
                ) ;
                }
                // c_name =  category.name;
                // c_badgcolor = category.badgeColor;
                // c_badgtext = category.badgeText;
                // c_icon =  category.icon;
                // c_path = category.path;
              }
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                 {c_main}
                </React.Fragment>
              );
            })}
          </ul><br/>
          {/* <p className="mb-0 copyright">
            &copy; {new Date().getFullYear()} -{" "}
            <Link to="dashboard" className="text-muted">
              Work Zone Plus
            </Link>
          </p> */}
        </div>
      
        </div>
        
      </nav>
      
      
    );
  }
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar
  }))(Sidebar)
);
