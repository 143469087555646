import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import * as myConstClass from './constant.js';
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import { toastr } from "react-redux-toastr";
class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
	  
	  //alert('hii');
	  
	
	  
	  /* var admin_id= localStorage.getItem('uname'); 
	var login_type= localStorage.getItem('login_type'); 
		var login_id= localStorage.getItem('login_id'); 
	  
	  
	  
	//alert(login_type);
	  
	  
	   fetch(myConstClass.BASE_URL+'user/getunreadMessages/'+admin_id+'/'+login_id+'/'+login_type)
      .then(response => response.json())
      .then(response =>{
		  
		  
//this.setState({leave_types: response})

                       //alert(response);
if(response==1)
{
	       	toastr.error(
                        'Error',
                        "You have a new message",
                        this.options
                      )
}



}).catch(err => console.log(err))*/
	  
	  
	  
	  
	  
	  
	  
	  
  }

  render() {
    return (
      <Provider store={store}>
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Provider>
    );
  }
}

export default App;
