//import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import './datepicker.scss';
import { enableLightTheme } from "../../redux/actions/themeActions";
import React,{ PureComponent } from "react";
//import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import { MoreVertical, RefreshCw } from "react-feather";
import $ from 'jquery';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import 'react-quill/dist/quill.bubble.css'
//import * as Icon from "react-feather";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
 import cryptoRandomString from 'crypto-random-string';
import {
  button,
  Navbar,
  NavbarBrand,
  Breadcrumb,
  BreadcrumbItem, Buttonn,Row,Col, Label, Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardLink,
  CardText,
  Container, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CardImg,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import avatar3 from "../../assets/img/avatars/avatar-3.jpg";
import screenshotThemeModern from "../../assets/img/screenshots/modern.png";
import screenshotThemeDark from "../../assets/img/screenshots/dark.png";
import screenshotThemeLight from "../../assets/img/screenshots/light.png";

import brandBootstrap from "../../assets/img/brands/bootstrap.svg";
import brandBootstrapB from "../../assets/img/brands/b.svg";
import brandSass from "../../assets/img/brands/sass.svg";
import brandWebpack from "../../assets/img/brands/webpack.svg";
import brandNpm from "../../assets/img/brands/npm.svg";
import brandReact from "../../assets/img/brands/react.svg";
import brandRedux from "../../assets/img/brands/redux.svg";

import IosPulse from "react-ionicons/lib/IosPulse";
//import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
//import './common.scss';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toastr } from "react-redux-toastr";
import * as Icon from "react-feather";
import CsvDownloader from 'react-csv-downloader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import  { Redirect } from 'react-router-dom' 
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";

const tableColumns = [ 
    {
      dataField: "JobTitle",
      text: "Title",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "JobID",
      text: "Code",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "Position",
      text: "Description",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width:"10%" },
      csvExport:false
    },
    
  ];


const Header = () => (
  <Navbar dark expand="xs" className="absolute-top w-100 py-2" style={{'backgroundColor': '#3e4676','height': '60px'}}>
    <Container>
	{/*   <NavbarBrand className="font-weight-bold" href="/">
        <IosPulse /> Spark
      </NavbarBrand>
      <Button
        color="success"
        href="https://themes.getbootstrap.com/product/spark-admin-dashboard-template-reactjs/"
        target="_blank"
        rel="noreferrer noopener"
        className="my-2 ml-2 btn-pill"
      >
        Purchase Now
      </Button>*/}
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="pt-7 pb-5 landing-bg text-white overflow-hidden">
    <Container className="py-4">
      <Row>
        <Col xl={11} className="mx-auto">
          <Row>
            <Col md={12} xl={8} className="mx-auto text-center">
              <div className="d-block my-4">
                <h1 className="display-4 font-weight-bold mb-3 text-white">
                  Modern, Flexible and Responsive ReactJS Admin Template
                </h1>
                <p className="lead font-weight-light mb-3 landing-text">
                  A professional package that comes with hunderds of UI
                  components, forms, tables, charts, dashboards, pages and svg
                  icons. Each one is fully customizable, responsive and easy to
                  use.
                </p>

                <div className="my-4">
                  <img
                    src={brandBootstrap}
                    alt="Bootstrap"
                    width="40"
                    className="align-middle mr-2"
                  />{" "}
                  <img
                    src={brandSass}
                    alt="Sass"
                    width="40"
                    className="align-middle mr-2"
                  />{" "}
                  <img
                    src={brandWebpack}
                    alt="Webpack"
                    width="40"
                    className="align-middle mr-2"
                  />{" "}
                  <img
                    src={brandNpm}
                    alt="NPM"
                    width="40"
                    className="align-middle mr-2"
                  />{" "}
                  <img
                    src={brandReact}
                    alt="React"
                    width="42"
                    className="align-middle"
                  />{" "}
                  <img
                    src={brandRedux}
                    alt="Redux"
                    width="34"
                    className="align-middle"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);
const tablestyle={
	'border-top': 'none',
'border-right': '1px solid #dee2e6',
'font-size': '16px',
'width':'30%',
'padding-right': '15px',
};
const tablestyletd={
	'border-top': 'none',
	'font-size': '16px',

'padding-left': '15px',
};
const Navigation = () => (
  <div className="py-3 bg-white landing-nav">
    <Container className="text-center">
      <Button
        tag={Link}
        to="/dashboard"
        color="primary"
        size="lg"
        target="_blank"
        className="btn-pill"
      >
        Preview
      </Button>
      <Button
        tag={Link}
        to="/documentation"
        color="link"
        size="lg"
        target="_blank"
        className="btn-pill text-dark"
      >
        Documentation
      </Button>
      <Button
        tag={Link}
        to="/documentation"
        color="link"
        size="lg"
        target="_blank"
        className="btn-pill text-dark"
      >
        Changelog
      </Button>
      <Button
        href="mailto:support@bootlab.io"
        color="link"
        size="lg"
        target="_blank"
        className="btn-pill text-dark"
      >
        Support
      </Button>
    </Container>
  </div>
);


const Testimonials = () => (
  <section className="py-6 bg-white">
    <Container>
      <div className="mb-4 text-center">
        <h2>Testimonials</h2>
        <p className="text-muted">
          Here's what some of our 2,500 customers have to say about working with
          our products.
        </p>
      </div>

      <Row>
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  {/* <Col md={6} lg={4}>
          <Card tag="blockquote" className="border">
            <CardBody className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrapB}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="pl-3">
                  <h5 className="mb-1 mt-2">Nikita</h5>
                  <small className="d-block text-muted h5 font-weight-normal">
                    Bootstrap Themes
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “We are totally amazed with a simplicity and the design of the
                template. Probably saved us hundreds of hours of development. We
                are absolutely amazed with the support Bootlab has provided us.”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card tag="blockquote" className="border">
            <CardBody className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrapB}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="pl-3">
                  <h5 className="mb-1 mt-2">Alejandro</h5>
                  <small className="d-block text-muted h5 font-weight-normal">
                    Bootstrap Themes
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “Everything is so properly set up that any new additions I'd
                make would feel like a native extension of the theme versus a
                simple hack. I definitely feel like this will save me hundredths
                of hours I'd otherwise spend on designing.”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card tag="blockquote" className="border">
            <CardBody className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrapB}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="pl-3">
                  <h5 className="mb-1 mt-2">Jordi</h5>
                  <small className="d-block text-muted h5 font-weight-normal">
                    Bootstrap Themes
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “I ran into a problem and contacted support. Within 24 hours, I
                not only received a response but even an updated version that
                solved my problem and works like a charm. Fantastic customer
                service!”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card tag="blockquote" className="border">
            <CardBody className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrapB}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="pl-3">
                  <h5 className="mb-1 mt-2">Jason</h5>
                  <small className="d-block text-muted h5 font-weight-normal">
                    Bootstrap Themes
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “As a DB guy, this template has made my life easy porting over
                an old website to a new responsive version. I can focus more on
                the data and less on the layout.”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card tag="blockquote" className="border">
            <CardBody className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrapB}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="pl-3">
                  <h5 className="mb-1 mt-2">Richard</h5>
                  <small className="d-block text-muted h5 font-weight-normal">
                    Bootstrap Themes
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “This template was just what we were after; its modern, works
                perfectly and is visually beautiful , we couldn't be happier.
                The support really is excellent, I look forward to working with
                these guys for a long time to come!”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card tag="blockquote" className="border">
            <CardBody className="p-4">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    src={brandBootstrapB}
                    width="48"
                    height="48"
                    alt="Bootstrap"
                  />
                </div>
                <div className="pl-3">
                  <h5 className="mb-1 mt-2">Martin</h5>
                  <small className="d-block text-muted h5 font-weight-normal">
                    Bootstrap Themes
                  </small>
                </div>
              </div>
              <p className="lead mb-2">
                “I just began to test and use this theme and I can find that it
                cover my expectatives. Good support from the developer.”
              </p>

              <div className="landing-stars">
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />{" "}
                <FontAwesomeIcon icon={faStar} />
              </div>
            </CardBody>
          </Card>
	  </Col>*/}
      </Row>
    </Container>
  </section>
);

const Footer = () => (
  <section className="py-5" style={{'background-color': '#3e4676'}}>
    <Container className="text-center">
      <Row>
        <Col lg={6} className="mx-auto">
          
        </Col>
      </Row>
    </Container>
  </section>
);

class Landing extends PureComponent {
	
	
	
	    constructor(props) {
      super(props);
      //this.routeChange = this.routeChange.bind(this);
     // this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
      this.search = this.search.bind(this);
	  //this.toggledocTemplate1=this.toggledocTemplate1(this);
	   this.state={'file':''}
      this.state = {
        user_id:0,
        formData:'',
        emp:'',
        posts:'',
        timeOut: 5000,
        showCloseButton: true,
        progressBar: true,
        position: "top-right",
		jobpostid:'',
		count:'',
		text1:'',
		text2:'',
		text3:'',text4:'',text5:'',
		text6:'',
		text7:'',
		text8:'',
		text9:'',
		text10:'',
		 visiblepoints: false ,
		 visiblepoints1:false,
		 submitted: false,
		 organization_url_see:'',
		 organization_logo_see:'',
		  organization_title_see:'',
		  job_title_see:'',
		  job_desc_see:'',
		  textShort_Description:'',
		  textJobTitle:'',
		  Longdescwithouthtml:'',
		  isreadlong:'',
		  admin_id:'',
      }
	  
	  this.state={jobpostidnew:'',selected:[],
		 costs:[],imagess:[],titleorg:[],
		 
		 
		 job_title:[],job_desc:[],
		 
		 }
      this.ochange = this.ochange.bind(this);
	  this.change = this.change.bind(this);
	   this.change1 = this.change1.bind(this);
      //this.oid=0;

      //for export
      this.csvColumns = [
        {
           displayName: "Title", 
           id: "title"
       },
        { displayName: 'Code',
        id: "code"
      },  
        { displayName: "Description", 
        id: "description" },
    
      ];

      //submitted: false,
    }

    edit(e) { 
        //this.props.history.push('/'+myConstClass.TENANT_PAGE_PATH+'jobpost/add/'+e);
		const id1=cryptoRandomString({length: 10, type: 'url-safe'});
		
		this.props.history.push({
          pathname : '/'+myConstClass.TENANT_PAGE_PATH+'blog/add/'+id1,
          state :{
          _id : e,
          }
          } 
        );
      }


      remove(e) { 
        var admin_id = localStorage.getItem('uname'); 
		
		//var
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to delete this record?",
          icon: "warning",
          // buttons: true,
          buttons: {            
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            },
            cancel: {
              text: "Cancel",
              value: false,
              visible: true,
              className: "Custom_Cancel",
              closeModal: true,
            }     
      },
          dangerMode: true,
        }).then((result) => { 
          if (result){
        const options = {
          timeOut: parseInt(this.state.timeOut),
          showCloseButton: this.state.showCloseButton,
          progressBar: this.state.progressBar,
          position: this.state.position
        };
        this.flag = 1;
        fetch(myConstClass.CARREER_URL+`user/deletejobpost/`+admin_id+'/'+e, {
        method: 'POST',
        body: e,
      }).then(toastr.success(
        'Success',
        "Job Post Deleted Successfully..!",
        options
      ),   
      fetch(myConstClass.CARREER_URL+'user/getjobpostInfoall/'+admin_id)
        .then(response => response.json())
      .then(response =>{
        let i=1;  
     
      this.setState({formData: response})
    }).catch(err => console.log(err))
      )
      .catch();
    }
        });    
      }






toggledocTemplate=  open_mod1 => {
   
      this.state.jobpostid =  open_mod1;
      this.setState(state => ({
        [open_mod1]: !state[open_mod1]
      })); 

     
    };

toggledocTemplateApply=  open_mod1 => {
 //  alert(open_mod1);
 //  alert(open_mod1);
   
   
   
            fetch(myConstClass.CARREER_URL+'user/getjobpostInfo/'+open_mod1)
            .then(response => response.json())
            .then(response =>{
				
				this.setState({ text1: response.Long_Description })
				 this.setState({ textJobTitle: response.JobTitle })

				 this.setState({ textShort_Description: response.Short_Description })

				// this.setState({ Long_Description: response.Long_Description })
			  //this.setState({JD_Title:[{ value:response.JD_Title, label:response.jdtitle }]});
				
				
				
				
				}).catch(err => console.log(err));
   
   
   
   
   
   
   
   
      this.state.jobpostid =  open_mod1;
      this.setState(state => ({
        [open_mod1]: !state[open_mod1]
      })); 

     
    };

toggleEdit = open_mod => { 
     
this.setState({'visiblepoints':false});
};



toggleDes = open_mod => { 
      //this.state.jobpostidnew =  open_mod;
     
this.setState({'visiblepoints1':true});



this.setState({'visiblepoints1':true});






};
toggleDesMore = open_mod => { 
      //this.state.jobpostidnew =  open_mod;
     
//this.setState({'visiblepoints1':true});



//this.setState({'visiblepoints1':true});
 $("#readmore").hide();
  $("#notreadmore").show();





};
toggleDesLess = open_mod => { 
      //this.state.jobpostidnew =  open_mod;
     
//this.setState({'visiblepoints1':true});

  $("#notreadmore").hide();
  $("#readmore").show();
//this.setState({'visiblepoints1':true});






}

toggleEdit1 = open_mod => { 
      //this.state.jobpostidnew =  open_mod;
      this.setState(state => ({
        [open_mod]: !state[open_mod]
      })); 
this.setState({'visiblepoints1':false});
};







toggledocTemplate1(e) { 
	
	//alert(e);
	
      this.state.jobpostidnew =  e;
      

this.setState({'visiblepoints':true});



 //this.state.user_id = answer_array['5'];
            fetch(myConstClass.CARREER_URL+'user/getjobpostInfo/'+this.state.jobpostidnew)
            .then(response => response.json())
            .then(response =>{
				
				 this.setState({ text1: response.Long_Description })
				 this.setState({ text2: response.JobTitle })
				 this.setState({ text3: response.JobType })
				 this.setState({ text4: response.Position })
				 this.setState({ text5: response.date_of_closing })
				  this.setState({ url: response.http_link })
				  this.setState({ Longdescwithouthtml: response.Long_Descriptionwithouthtml })
				  this.setState({ isreadlong: response.isreadlong })
				// this.setState({ text6: response.Gender })
				if(response.Gender == '1'){ 
				this.setState({ text7:'Male'  })
                 
              } else if(response.Gender == '2') {
				  this.setState({ text7:'Female'  })
              
              }else if(response.Gender == '3') {
                  this.setState({ text7:'other'  })   
              } else{
               this.setState({ text7:'Male'  })  
              }
			  
			  
			  
			  if(response.Minimum_Experience == 1){ 
				this.setState({ text6:'Fresher'  })
                 
              } else if(response.Minimum_Experience == 2) {
				  this.setState({ text6:'1 Year'  })
              
              }else if(response.Minimum_Experience == 3) {
                  this.setState({ text6:'2 Year'  })   
              }
			  else if(response.Minimum_Experience == 4) {
				  this.setState({ text6:'3 Year'  })
              
              }else if(response.Minimum_Experience == 5) {
                  this.setState({ text6:'4 Year'  })   
              }
			  else if(response.Minimum_Experience == 6) {
				  this.setState({ text6:'5 Year'  })
              
              }else if(response.Minimum_Experience == 7) {
                  this.setState({ text6:'6 Year'  })   
              }
			  else if(response.Minimum_Experience == 8) {
				  this.setState({ text6:'7 Year'  })
              
              }else if(response.Minimum_Experience == 9) {
                  this.setState({ text6:'8 Year'  })   
              }
			  else if(response.Minimum_Experience == 10) {
				  this.setState({ text6:'9 Year'  })
              
              }
			  else if(response.Minimum_Experience == 11) {
				  this.setState({ text6:'10 Year'  })
              
              }
			  else if(response.Minimum_Experience == 12) {
				  this.setState({ text6:'10+ Year'  })
              
              }
			
			 this.setState({ text9: response.Positions })
				 this.setState({ text8: response.Short_Description })
				  this.setState({ text10: response.posted_date})
				// this.setState({ Long_Description: response.Long_Description })
			  //this.setState({JD_Title:[{ value:response.JD_Title, label:response.jdtitle }]});
				
				
				
				
				}).catch(err => console.log(err));






















     
    };









      // remove(e) { 
      //   this.flag = 1;
      //   var admin_id = localStorage.getItem('uname'); 
      //   const options = {
      //       timeOut: parseInt(this.state.timeOut),
      //       showCloseButton: this.state.showCloseButton,
      //       progressBar: this.state.progressBar,
      //       position: this.state.position
      //     };
      //     this.flag = 1;
      //   confirmAlert({
      //     title: '',
      //     message: 'Are you sure ?',
      //     buttons: [
      //       {
      //         label: 'Yes',
      //         onClick: () =>    fetch(myConstClass.BASE_URL+`announcements/deleteAnnouncement`, {
      //           method: 'POST',
      //           body: e,
      //         }).then(toastr.success(
      //           'Success',
      //           "Announcement Deleted Successfully..!",
      //           options
      //         ), fetch(myConstClass.BASE_URL+'announcements/getAnnouncement/'+admin_id)
      //         .then(response => response.json())
      //         .then(response =>{
      //           let i=1;  
      //         response.forEach(element => {
      //           let id=element.uid;
      //         element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.edit(id)} /><Icon.Trash2 style={{cursor:'Pointer'}} onClick={() =>this.remove(id)}/></div>;
      //          // console.log(element);
      //          element.uid = i;
      //          i++;
      //         });
      //         this.setState({formData: response})
      //       }).catch(err => console.log(err))
      //         )
      //         .catch()
      //       },
      //       {
      //         label: 'No',
      //         onClick: () => <Redirect to='announcements/listing'  />
      //       }
      //     ]
      //   });
      // }
      ochange(event){
        var admin_id= localStorage.getItem('uname'); 
		 var new_account_id= localStorage.getItem('account_id'); 
        this.oid = event.target.value;
        fetch(myConstClass.CARREER_URL+'product/getProductCategory/'+admin_id+'/'+this.oid)
        .then(response => response.json())
      .then(response =>{
        let i=1;  
      response.forEach(element => {
        let id=element.id;
      element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.edit(id)} /><Icon.Trash2 style={{cursor:'Pointer'}} onClick={() =>this.remove(id)}/></div>;
       // console.log(element);
       element.id = i;
       i++;
      });
      this.setState({formData: response})
    }).catch(err => console.log(err))
            
      }

      search(event){
        var admin_id= localStorage.getItem('uname'); 
        this.sid = event.target.value;
        fetch(myConstClass.CARREER_URL+'product/getProductCategory/'+admin_id+'/'+this.sid+'/'+this.oid)
        .then(response => response.json())
      .then(response =>{
        let i=1;  
      response.forEach(element => {
        let id=element.id;
      element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.edit(id)} /><Icon.Trash2 style={{cursor:'Pointer'}} onClick={() =>this.remove(id)}/></div>;
       // console.log(element);
       element.id = i;
       i++;
      });
      this.setState({formData: response})
    }).catch(err => console.log(err))
            
      }
  

      
	  
	  
	   change = (selectedOrg) => {
          this.setState({ selectedOrg});
        var new_account_id = localStorage.getItem('account_id'); 
        // this.cid = event.target.value;
        if(selectedOrg == null || selectedOrg == undefined){
          this.cid = 0; 
        } else {
          let x = Object.values(selectedOrg);
          // this.dep_id = event.target.value;
          this.cid = x[0];
        }
        if(this.pos_id == null || this.pos_id == ''){
          this.pos_id = 0;
         } else {
          this.pos_id =  this.pos_id;
         } 
         if(this.dep_id == null || this.dep_id == ''){
          this.dep_id = 0;
         } else {
          this.dep_id =  this.dep_id;
         } 
 if(this.oid == null || this.oid == undefined){
          this.oid = 0; 
        } else {
          this.oid =  this.oid;
        }

        fetch(myConstClass.CARREER_URL+'user/getjobpostInfoallc/'+new_account_id+'/'+this.cid+'/'+this.oid)
      .then(response => response.json())
      .then(response =>{
        let i=1; 
 let c=1; 		
      response.forEach(element => {
        let id=element.id;
      element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.toggledocTemplate(id)} /></div>;
       // console.log(element);
      // element.id = i;
       i++;
	   c++;
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
      });
	  
	 //this.setState({org: response})
      this.setState({formData: response})
	   this.setState({count: c})
    }).catch(err => console.log(err))
       












	   
      }
	  
	  
	  
	  
	 change1 = (selectedOrg) => {
          this.setState({ organization_id1:selectedOrg});
        var new_account_id = localStorage.getItem('account_id'); 
        // this.cid = event.target.value;
        if(selectedOrg == null || selectedOrg == undefined){
          this.oid = 0; 
        } else {
          let x = Object.values(selectedOrg);
		 // let xii = indexOf(selectedOrg);
		  //alert(xii);
          // this.dep_id = event.target.value;
          this.oid = x[0];
        }
		
		
		
		var indexoforg=this.state.selected.indexOf(this.oid);
		
		//alert(indexoforg);
		
		
		var organization_url=this.state.costs[indexoforg];
		var organization_logo=this.state.imagess[indexoforg];
		var organization_title=this.state.titleorg[indexoforg];
		//alert(this.state.costs);
	this.setState({organization_url_see: organization_url});
		this.setState({organization_logo_see: organization_logo});
		this.setState({organization_title_see: organization_title});
        if(this.pos_id == null || this.pos_id == ''){
          this.pos_id = 0;
         } else {
          this.pos_id =  this.pos_id;
         } 
         if(this.dep_id == null || this.dep_id == ''){
          this.dep_id = 0;
         } else {
          this.dep_id =  this.dep_id;
         } 


        fetch(myConstClass.CARREER_URL+'user/getjobpostInfoallc/'+new_account_id+'/'+this.cid+'/'+this.oid)
      .then(response => response.json())
      .then(response =>{
        let i=1; 
 let c=1; 		
      response.forEach(element => {
        let id=element.id;
      element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.toggledocTemplate(id)} /></div>;
       // console.log(element);
      // element.id = i;
       i++;
	   c++;
      });
	  
	 //this.setState({org: response})
      this.setState({formData: response})
	   this.setState({count: c})
    }).catch(err => console.log(err))
       












	   
      } 
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
      componentDidMount(){
		                 
						 
			if( window.localStorage )
    {
      if( !localStorage.getItem('firstLoad') )
      {
        localStorage['firstLoad'] = true;
         window.location.reload();
      }  
      else
        localStorage.removeItem('firstLoad');
    }			 
						 
						 
					 const answer_array = this.props.location.pathname.split('/');	 
						 
						// alert(answer_array);
						var account_name=answer_array[3]; 
						 
					

fetch(myConstClass.CARREER_URL+account_name+'/index.php/user/get_admin_info_byacna/'+account_name)
            .then(response => response.json())
            .then(response =>{
				
				//this.setState( {disabled: true} )
				//alert(response);
				 localStorage.setItem('account_id',response);
				/*if(response.host_type=='1')
				{
					this.setState({
      showResults: event.target.value=='0' ? false : true
    });
				}*/
				
				
			          // alert(response);        	
				
				
            //this.setState({admin_id:response});
          }).catch(err => console.log(err))   




//alert(localStorage.getItem('account_id'));







						
						 
		  var new_account_id= localStorage.getItem('account_id'); 
		  fetch(myConstClass.CARREER_URL+account_name+'/index.php/organization/get_all_organizations/'+new_account_id)
        .then(response => response.json())
        .then(response =>{
			
			
			
				  response.forEach(element => {
		  //this.setState({totalccspan: response.totalAmount});
		// alert(element.id);
		  
        let id=element.id;
    /*  element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.edit(id)} /><Icon.Trash2 style={{cursor:'Pointer'}} onClick={() =>this.remove(id)}/></div>;
       // console.log(element);
       element.id = i;
       i++;*/
	   
	  this.setState(() => ({
       // selected: this.state.selected ? [...this.state.selected, row.id] : []
		 selected: [...this.state.selected, element.id]
      }));
	   
	   
	   this.setState(() => ({
       // selected: this.state.selected ? [...this.state.selected, row.id] : []
		 costs: [...this.state.costs, element.http_link]
      }));
	   
	  
	   this.setState(() => ({
       // selected: this.state.selected ? [...this.state.selected, row.id] : []
		 imagess: [...this.state.imagess, element.org_logo_url]
      }));
	  
	  
	  
	  this.setState(() => ({
       // selected: this.state.selected ? [...this.state.selected, row.id] : []
		 titleorg: [...this.state.titleorg, element.title]
      }));
	  
	  
	  
	  
	  
	  
	  
	  
      });
			
			
			var organization_id1=this.state.selected[0];
			//alert(organization_id1);
			var organization_url=this.state.costs[0];
		var organization_logo=this.state.imagess[0];
		var organization_title=this.state.titleorg[0];
		//alert(this.state.costs);
	       this.setState({organization_url_see: organization_url});
		this.setState({organization_logo_see: organization_logo});
		this.setState({organization_title_see: organization_title});
			this.setState({organization_id11: organization_id1});
			
			this.setState({organization_id1:[{ value: organization_id1, label: organization_title }]});   
			this.setState({emp: response})}).catch(err => console.log(err))  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		//alert(this.oid);
		  
		  
		  
 if(this.cid == null || this.cid == undefined){
          this.cid = 0; 
        } else {
          this.cid =  this.cid;
        }
		
		 if(this.oid == null || this.oid == undefined){
           this.oid = this.state.organization_id11; 
        } else {
          this.oid =  this.oid;
        }
       
   // document.body.appendChild(script);  

   //    document.querySelector('.react-bootstrap-table').style.minHeight  = "243px";
        
        
        fetch(myConstClass.CARREER_URL+account_name+'/index.php/user/getjobpostInfoallc/'+new_account_id+'/'+this.cid+'/'+this.oid)
      .then(response => response.json())
      .then(response =>{
        let i=1; 
 let c=1; 		
      response.forEach(element => {
        let id=element.id;
      element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.toggledocTemplate(id)} /></div>;
       // console.log(element);
      // element.id = i;
       i++;
	   c++;
	   
	   
	    
	   
	   this.setState(() => ({
       // selected: this.state.selected ? [...this.state.selected, row.id] : []
		 job_title: [...this.state.job_title, element.JobTitle]
      }));
	   
	   
	   this.setState(() => ({
       // selected: this.state.selected ? [...this.state.selected, row.id] : []job_titlejob_desc
		 job_desc: [...this.state.job_desc, element.Short_Description]
      }));
	   
	  
	  
	  
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
      });
	  
	//  this.setState({org: response})
      this.setState({formData: response})
	   this.setState({count: c})
    }).catch(err => console.log(err))
//var admin_id= localStorage.getItem('uname'); 
        fetch(myConstClass.CARREER_URL +account_name+'/index.php/organization/get_all_jd/'+new_account_id)
        .then(response => response.json())
        .then(response => { this.setState({ org: response }); }).catch(err => console.log(err));  
   
   
   
   
   
   
     
   
   
   
   
   
   
    }

   

onDocChangeHandler(e) {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          // imagePreviewUrl: reader.result
        });
      }
      reader.readAsDataURL(file)
    }
	
	
	
	
  handleValidSubmitDocument = () => {
        var admin_id= localStorage.getItem('uname'); 
		var new_account_id= localStorage.getItem('account_id');
//alert(this.state.file);
if(this.state.file!=undefined)
{
        this.setState({ submitted: true }, () => {  
            setTimeout(() => this.setState({ submitted: false }), 5000);
            const data = new FormData() 
            data.append('file', this.state.file);
			 data.append('jobpostid', this.state.jobpostid);
            data.append('formdata',JSON.stringify($('#doform').serializeArray()));  
            fetch(myConstClass.CARREER_URL+`user/add_applydocumment/`+new_account_id, {
                    method: 'POST',
                    body:data,
                    // contentType: "application/json; charset=utf-8",
                  }).then(response =>  response.json()).then((response) => {
                    if(response == true){
						
						
						
						
						//this.state.jobpostid =  open_mod1;
      this.setState(state => ({
        [this.state.jobpostid]: !state[this.state.jobpostid]
      })); 
						
						
						
						
						
						
						
						
						
                      (this.state.user_id =='0' ) ?
                      toastr.success(
                        'Success',
                        "Resume Uploaded Successfully..!",
                        this.options
                      ) : toastr.success(
                        'Success',
                        "Resume Uploaded Successfully..!",
                        this.options
                      );
    
                     // this.props.history.push('/'+myConstClass.TENANT_PAGE_PATH+'complaints/listing');
                    } else{
                      toastr.error(
                        'Error',
                       "Resume Not Uploaded Successfully..!",
                        this.options
                      )
                    }
                  })
                  .catch();
         });
}
else{
	
	toastr.error(
                        'Error',
                        "Please Upload Resume ..!",
                        this.options
                      )
}
      }
                    
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(enableLightTheme());
  }

  render() {
	   var admin_id= localStorage.getItem('uname'); 
        const { org ,formData,count,text1,
		text2,text3,text4,text5,text6,text7,text8,text9,text10
		,submitted,url,emp,organization_url_see,img,imagepreview,organization_logo_see,organization_title_see
		,organization_id1,job_desc_see,job_title_see,textShort_Description,textJobTitle,Longdescwithouthtml,
		  isreadlong
		} = this.state; 

let orgOp =  org ?  org.map((cell, i) => {  
          return { value:cell.id, label: cell.title };
          }) : '';

 let orgOp1 = emp ?  emp.map((cell, i) => {
          return { value: cell.id, label: cell.title };
        }): '';
let balance_div_url;
let organization_div_url,organization_title_url;
   balance_div_url=url?<a target="_blank"  class="mr-1 mb-1 btn btn-outline-secondary" href={url}  >See on CamJOBS <Icon.ArrowUpRight style={{cursor:'Pointer'}} /></a>:'';
  // organization_div_url=organization_url_see?<a target="_blank" href={organization_url_see}  >See on CamJOBS for this organization</a>:'';
organization_title_url=organization_title_see?<h1>{organization_title_see}</h1>:'';
   organization_div_url=organization_url_see?<a  class="mr-1 mb-1 btn btn-outline-secondary"  href={organization_url_see} target="_blank">See on CamJOBS <Icon.ArrowUpRight style={{cursor:'Pointer'}} /></a>:'';
 //organization_div_url=organization_url_see?<Link to={organization_url_see} style={{margin:"0rem 1.50rem 1.50rem 0rem",border: "#6f42c1 1px solid",color: "#6f42c1",padding: "0.10rem 0.90rem 0.30rem 0.90rem",textDecoration: 'none',borderRadius:'0.2rem'}} target="_blank">Cancel</Link>:'';

 let file_link,image_logo;
	   file_link =myConstClass.SITE_URL+organization_logo_see;
	image_logo=organization_logo_see?<img src={file_link}  style={{width: '190px',height:'100px'}}/>:'';









let balance_div;
        let bal,file_linknew,readmore;
        balance_div =   formData ?  formData.map((cell, i) => {
			file_linknew = myConstClass.DOCS_URL+`uploads/job_post_image/`+cell.id+'/'+cell.image;
			readmore = cell.isread=='1'?<CardLink href="#" onClick={() => {}}>Read More</CardLink>:'';
          return <Row> 
                    <Col lg={12}>
				  <Card style={{'height': '130px'}}>
				  
				  
				  
				 
				  
				  
				  
				  
				  
				  
				  
				  
				  
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
	  
	  <Row> 
	   <Col lg={2}>
	  <CardImg style={{'height': '98px','width':'180px'}} src={file_linknew} alt="Card image cap" /> 
	  
	  
	  
	  
	  
	  
                   
					
					
					
				</Col> 
				
				<Col >
					
				 	<label style={{'font-weight-bold':'100px','width':'750px'}}>{cell.JobTitle}</label>
					
					
					
			<button class="mr-1 mb-1 btn btn-outline-secondary"    onClick={() =>
					
					
					
					
					
					this.toggledocTemplate1(cell.id)
					
					
					}>View More</button>
					
					<Button  class="secondary" style={{'marginLeft':'10px','marginTop':'-5px'}} onClick={() => this.toggledocTemplateApply(cell.id)}>Apply
                 </Button>
					
					
					
					<br/>
					{cell.Short_Description} {readmore}
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
					  
				</Col> 
				
				
				
                  </Row>	
					
       
      </CardTitle>
    </CardHeader>
   
  </Card> 
   </Col> 
                  </Row>;
				  
				  
				  
				  
				  
				  
				  
				  
				  
				  
				  
				  
				  
				  
				  
           }) : '';



      //for export csv
      const csvColumns= this.csvColumns;
      const csvData =this.state.formData;
      let fileName = 'Announcements_'+new Date().getTime()+'.csv';
	 
    return (
	
	<div class = "wrapper" style={{width: '1189px'}}>
      <React.Fragment>
        <Header />
		{/* <Intro /> */}
    
       {/* <Navigation />*/}
       <section className="py-6">
    <Container>
	 <Row>
		   
		
		   <Col lg={6} style={{'textAlign':'right'}}>
	

	{/*<Link to={'/'+myConstClass.TENANT_PAGE_PATH+"dashboard"} style={{margin:"0rem 1.50rem 1.50rem 0rem",border: "#6f42c1 1px solid",color: "#6f42c1",padding: "0.10rem 0.90rem 0.30rem 0.90rem",textDecoration: 'none',borderRadius:'0.2rem'}}>Back To Dashboard</Link>*/}
	
	
	
	
	
	{image_logo}
	
	
	
	
	
	
	
	
	
	
	
	</Col>
	<Col lg={4} style={{'textAlign':'left',marginTop:'38px'}}>
		{organization_title_url}
	  </Col>
	   <Col lg={2} style={{'textAlign':'right','paddingTop':'39px'}}>
	   {organization_div_url}
		   </Col>
	 <Col lg={4}>
	<tr></tr>
         </Col>             
	 </Row>
	 <br/>
      <div className="mb-4 text-center">
        <h2>Available Jobs({count-1})</h2>
        <p className="text-muted">
         <label>  Following are available Jobs</label>
		  
		 
        </p>
    
	
	
	
	
          <Row>
		  
		  
		   <Col lg={6}>
                      <Select
                           value={organization_id1}
                           name="organization_id1"
                           onChange={this.change1}
                           options={orgOp1}
                          isClearable
                          placeholder='Select Organization'
                      />
	
	
		  </Col>
		  
		  
		  
		  
		  
		  
		  
	<Col lg={6}>      
<Select
                           value={this.state.organization_id}
                           name="organization_id"
                           onChange={this.change}
                           options={orgOp}
                          //isClearable
                          placeholder='Select Job Type'
                      />
	</Col>
	</Row>
 </div>
        {balance_div}
		 <Modal
               size="lg" style={{maxWidth: '900px', width: '100%'}}
                isOpen={this.state[this.state.jobpostid]}
             //   toggle={() => this.toggleEdit(this.state.jobpostid)}
              >
			   <AvForm id="doform" onValidSubmit={this.handleValidSubmitDocument}>  
			  <ModalHeader  style={{'backgroundColor':'#D9DDDE'}} >
                <label style={{'width':'705px'}}>Applying For Job Post: {textJobTitle}</label><br/>
			  
			  {textShort_Description}
               
                </ModalHeader>
                <ModalBody >
                  <CardBody>
				
                   
                    <Row>
                  <Col lg={6}>
                  <AvGroup>
                   <Label for="example">Name</Label>
                   <AvInput name="title" id="example" required />
                   <AvFeedback>Enter Name!</AvFeedback>
                  </AvGroup>
                 </Col>
                   
				    <Col lg={6}>
                  <AvGroup>
                   <Label for="example">Mobile No</Label>
                   <AvInput name="mobile_no" id="example" type="number" required />
                   <AvFeedback>Enter Mobile No!</AvFeedback>
                  </AvGroup>
                 </Col>
				   
				    <Col lg={6}>
                  <AvGroup>
                   <Label for="example">Email</Label>
                   <AvInput name="email" type="email" id="example" required />
                   <AvFeedback>Enter Email!</AvFeedback>
                  </AvGroup>
                 </Col>
				   
				  
				   
				   
				   <Col lg={6}>
                <AvGroup>
                  <Label for="example">Upload Resume</Label>   <br/> 
                       <input type="file"  id= 'file1' name="file" onChange={(e)=>this.onDocChangeHandler(e)} required/>  
 <AvFeedback>Upload Resume!</AvFeedback>					   
                    </AvGroup> 
                    </Col>
				    
				   
				   
				   
				   
				   
				   
                   </Row>
				   
                   
                  </CardBody>
                </ModalBody>
                <ModalFooter >                              
                  
                  <div style={{'textAlign': 'right'}}>
				    <button style={{'marginTop': '37px'}} class="mr-1 mb-1 btn btn-outline-secondary" onClick={() => this.toggledocTemplate(this.state.jobpostid)}>
                    Close
                  </button>{" "}
                  <Button style={{'marginTop': '32px'}} disabled={submitted}>{
                              (submitted && 'Your form is submitted!')
                              || (!submitted && 'Apply')
                          }</Button>
					 </div>	 
				  
				  
				  
				  
				  
				 
				  
				  
				  
				  
				  
				  
                </ModalFooter>
				
				</AvForm><br/>
              </Modal>
			  
			  
			  
			  
			   <Modal
               size="lg" style={{maxWidth: '900px', width: '100%'}}
                  isOpen={this.state.visiblepoints}
                //toggle={() => this.toggleEdit(this.state.jobpostidnew)}
              >
                <ModalHeader  style={{'backgroundColor':'#D9DDDE'}} >
                <label style={{'width':'695px'}}>{text2}</label>{balance_div_url}<br/>
	
	
	 {text8}
	
      <div className="card-actions float-right">
        <span className="cursor-pointer mr-1">
         
        </span>{" "}
       
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
      </div>
                </ModalHeader>
                <ModalBody  className="m-9">
                 
     

      <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th className="text-right" style={tablestyle}>Job Type</th>
            <td className="text-left" style={tablestyletd}>{text3}</td>
          </tr>
          <tr>
            <th className="text-right" style={tablestyle}>Position</th>
            <td className="text-left" style={tablestyletd}>{text4}</td>
          </tr>
          <tr>
            <th className="text-right" style={tablestyle}>Date Of Closing</th>
            <td className="text-left" style={tablestyletd}>{text5}</td>
          </tr>
          <tr>
            <th className="text-right" style={tablestyle}>Date Of Posted</th>
            <td className="text-left"  style={tablestyletd}>{text10}</td>
          </tr >
          <tr>
            <th className="text-right" style={tablestyle}>Minimum_Experience</th>
            <td className="text-left" style={tablestyletd}>{text6}</td>
          </tr>
          <tr>
            <th className="text-right" style={tablestyle}>Gender</th>
            <td className="text-left" style={tablestyletd}>{text7}</td>
          </tr>
          <tr>
            <th className="text-right" style={tablestyle}>Long Description</th>
            <td className="text-left" style={tablestyletd}>
              
			  
			  
			 {  /*   
			 {Longdescwithouthtml}{isreadlong=='1'? (<CardLink href="#" onClick={() => this.toggleDes(this.state.jobpostidnew)}>Read More</CardLink>):''} */
			  }
			  
			  
			  <div id="readmore" style={{'display':'block'}}>
			  <ReactQuill value={this.state.Longdescwithouthtml || ''} readOnly={true}
   theme={"bubble"}
                  />{isreadlong=='1'? (<CardLink href="#" onClick={() => this.toggleDesMore(this.state.jobpostidnew)}>Read More</CardLink>):''}
			  </div>
			   <div id="notreadmore" style={{'display':'none'}}>
			  <ReactQuill value={text1 || ''} readOnly={true}
   theme={"bubble"}
                  /><CardLink href="#" onClick={() => this.toggleDesLess(this.state.jobpostidnew)}>Show Less</CardLink>
				  
			  </div>
			  
			 
			  
			  
			  
			  {/*  <Button color="secondary" onClick={() => this.toggleDes(this.state.jobpostidnew)}>
                    See Long Description
  </Button> */}
			  
			  
			    
			  
			  
			  
			  
			  
			  
			  
            </td>
          </tr>
		  
		  
		  <tr></tr>
        </tbody>
      </Table>
       </ModalBody>
                <ModalFooter >                              
                 
				   <Button style={{'marginTop': '37px'}}  class="mr-1 mb-1 btn btn-outline-secondary" onClick={() => this.toggleEdit(this.state.jobpostidnew) }>
				 
                    Close
                  </Button>{" "}
                 
                </ModalFooter>
              </Modal>
			  
			  
			  
			  
			  
			  
			  
			  
			  			   <Modal
               size="lg" style={{maxWidth: '900px', width: '100%'}}
                  isOpen={this.state.visiblepoints1}
                //toggle={() => this.toggleEdit(this.state.jobpostidnew)}
              >
                <ModalHeader >
                  <h4>View Long Description</h4>
                </ModalHeader>
                <ModalBody  className="text-center m-9">
                 <Card>
    <CardHeader>
     
      <CardTitle tag="h5" className="mb-0">
       Long Description of {text2}
      </CardTitle>
    </CardHeader>
    <CardBody>
     
<Row>
			     <Col lg={12}>
			  
 
    <ReactQuill value={this.state.text1} readOnly={true}
   theme={"bubble"}
                  />
</Col>
			   </Row>
    
    </CardBody>
  </Card>
                </ModalBody>
                <ModalFooter >                              
                  <Button style={{'marginTop': '37px'}}  class="mr-1 mb-1 btn btn-outline-secondary" onClick={() => this.toggleEdit1(this.state.jobpostidnew)}>
                    Close
                  </Button>{" "}
                 
                </ModalFooter>
              </Modal>
			  
			  
			  
			  
			  
		  
			  
			  
    </Container>
  </section>
       
       
      </React.Fragment>
	  
	  </div>
    );
  }
 }
//export default connect()(Landing)
export default Landing;